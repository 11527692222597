interface Props {
  count?: number;
}

const Counter = ({ count = 0 }: Props) => {
  if (!count) return null;
  return (
    <div className="Counter">
      <span>{count}</span>
      <style jsx>{`
        div {
          color: #fff;
          position: absolute;
          top: -6px;
          right: 0;
          background: #d60000;
          border-radius: 100px;
          min-width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 0.7em;
          border: 1px solid #fff;
        }
        span {
          margin: auto;
          display: inline-block;
          padding: 0 5px;
        }
      `}</style>
    </div>
  );
};

export default Counter;
