import { useContext, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { StoreHelpers } from 'react-joyride';
import ReactPlayer from 'react-player';
import { logClickButton } from '../../../../analytics';
import { HUB_USER_PERMISSION } from '../../../../interfaces';
import { PageView } from '../../../../utils/pageView';
import { shouldShowStorybooks } from '../../../utils/hubEntitlements';
import { hubNavTo } from '../../../utils/hubPagesRoute';
import Stepper from '../../Common/Stepper';
import { useTextContent } from '../../hooks/tour';
import { HubContext } from '../../HubContext';

const StepDetails = ({ helpers }: { helpers?: StoreHelpers }) => {
  const { tc } = useTextContent();
  const { setIsGuidedTourReplay } = useContext(HubContext) || {};

  const [currentStep, setCurrentStep] = useState(1);
  const totalStep = 4;
  const { storeId, brandId, meetUser, user, checkUserPermissions, chooseCheckPermissionResult } = useContext(HubContext) || {};

  const hasALPermission = checkUserPermissions(
    HUB_USER_PERMISSION.ASSET_LIBRARY
  );

  const hasSBPermission = chooseCheckPermissionResult(
    shouldShowStorybooks({
      storeId,
      brandId,
      permissions: meetUser?.permissions,
      userType: user?.userType
    }),
    checkUserPermissions(HUB_USER_PERMISSION.STORYBOOK)
  );

  const DetailsStep1 = () => {
    return (
      <div className="storiez_details">
        <div className="storiez_details__block">
          <div className="storiez_details__text">
          {tc({ key: 'tour:the' })} <strong>{tc({ key: 'tour:asset_library' })}</strong> {tc({ key: 'tour:your_cloud_storage' })}
          </div>
          <div className="storiez_details__text">
          {tc({ key: 'tour:you_have_access' })}
          </div>
        </div>
        <div className="storiez_details__list">
          <ul>
            <li>1. {tc({ key: 'tour:go_to_asset_library' })}</li>
            <li>2. {tc({ key: 'tour:upload_all_your_content' })}</li>
            <li>3. {tc({ key: 'tour:create_categories_and_tag_content_accordingly' })}</li>
          </ul>
        </div>
      </div>
    );
  };

  const DetailsStep2 = () => {
    return (
      <div className="storiez_details">
        <div className="storiez_details__block">
          <div className="storiez_details__text">
          {tc({ key: 'tour:the' })} <strong>{tc({ key: 'tour:storybook' })}</strong> {tc({ key: 'tour:is_your_presentation' })}
          </div>
          <div className="storiez_details__text">
            {tc({ key: 'tour:create_new_scenes' })}{' '}
            <strong>{tc({ key: 'tour:scene_library' })}</strong> {tc({ key: 'tour:reuse' })}{' '}
            <strong>{tc({ key: 'tour:storybooks' })}</strong>.
          </div>
        </div>
        <div className="storiez_details__list">
          <ul>
            <li>1. {tc({ key: 'tour:go_to_storybook' })}</li>
            <li>2. {tc({ key: 'tour:create_a_storybook' })}</li>
            <li>3. {tc({ key: 'tour:create_scenes_inside_storybook' })}</li>
            <li>4. {tc({ key: 'tour:activate_storybook' })}</li>
            <li>5. {tc({ key: 'tour:publish_storybook' })}.</li>
          </ul>
        </div>
        <div className="storiez_details__text">{tc({ key: 'tour:any_changes_scenes' })}</div>
      </div>
    );
  };

  const DetailsStep3 = () => {
    return (
      <div className="storiez_details">
        <div className="storiez_details__block">
          <div className="storiez_details__text">
          {tc({ key: 'tour:video_conferencing_system' })}
          </div>
        </div>
        <div className="storiez_details__list">
          <ul>
            <li>1. {tc({ key: 'tour:go_to_calendar' })}</li>
            <li>2. {tc({ key: 'tour:create_meeting' })}</li>
            <li>3. {tc({ key: 'tour:import_meeting' })}</li>
            <li>4. {tc({ key: 'tour:join_meeting' })}</li>
            <li>5. {tc({ key: 'tour:present_meeting' })}</li>
          </ul>
        </div>
      </div>
    );
  };

  const DetailsStep4 = () => {
    return (
      <div className="storiez_details">
        <div className="storiez_details__block">
          <div className="storiez_details__text">
          {tc({ key: 'tour:presentation_analytics' })}
          </div>
        </div>
        <div className="storiez_details__list">
          <ul>
            <li>1. {tc({ key: 'tour:go_to_storybook' })}</li>
            <li>2. {tc({ key: 'tour:search_storybook' })}</li>
            <li>3. {tc({ key: 'tour:select_insight' })}</li>
          </ul>
        </div>
      </div>
    );
  };

  const UploadAssetsBtn = () => {
    return (
      <button
        className="btn joyride-btn-redirect"
        disabled={!hasALPermission}
        onClick={() => {
          helpers.skip();
          hubNavTo(PageView.HUB_ASSET_LIBRARY);
        }}
      >
        <div className="joyride-button-text">
          {tc({ key: 'tour:storiez_upload_assets_now' })}
        </div>
      </button>
    );
  };

  const StartMeetingBtn = () => {
    return (
      <button
        className="btn joyride-btn-redirect"
        onClick={() => {
          helpers.skip();
          setIsGuidedTourReplay(true);
          hubNavTo(PageView.HUB_SESSION_OVERVIEW);
        }}
      >
        <div className="joyride-button-text">
          {tc({ key: 'tour:storiez_start_meeting' })}
        </div>
      </button>
    );
  };

  const CreateStoryBookBtn = () => {
    return (
      <button
      className="btn joyride-btn-redirect" 
      disabled={!hasSBPermission}
      onClick={() => {
        helpers.skip();
        hubNavTo(PageView.HUB_STORYBOOK);
      }}>
        <div className="joyride-button-text">
          {tc({ key: 'tour:storiez_create_storybook' })}
        </div>
      </button>
    );
  };

  const CreateAStoryBookBtn = () => {
    return (
      <button
      className="btn joyride-btn-redirect" 
      disabled={!hasSBPermission}
      onClick={() => {
        helpers.skip();
        hubNavTo(PageView.HUB_STORYBOOK);
      }}>
        <div className="joyride-button-text">
          {tc({ key: 'tour:storiez_create_a_storybook' })}
        </div>
      </button>
    );
  };

  const onBack = () => {
    if(currentStep === 1) helpers?.prev()
    else setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    if (currentStep === totalStep) {
      setCurrentStep(1);
      helpers?.next();
    } else setCurrentStep(currentStep + 1);
  };

  const stepContents = [
    {
      title: tc({ key: 'tour:storiez_title_step_1' }),
      url: 'https://videos.inspify.com/assets/storiez/step1/step1.m3u8',
      detail: <DetailsStep1 />,
      action: <UploadAssetsBtn />
    },
    {
      title: tc({ key: 'tour:storiez_title_step_2' }),
      url: 'https://videos.inspify.com/assets/storiez/step2/step2.m3u8',
      detail: <DetailsStep2 />,
      action: <CreateStoryBookBtn />
    },
    {
      title: tc({ key: 'tour:storiez_title_step_3' }),
      url: 'https://videos.inspify.com/assets/storiez/step3/step3.m3u8',
      detail: <DetailsStep3 />,
      action: <StartMeetingBtn />
    },
    {
      title: tc({ key: 'tour:storiez_title_step_4' }),
      url: 'https://videos.inspify.com/assets/storiez/step4/step4.m3u8',
      detail: <DetailsStep4 />,
      action: <CreateAStoryBookBtn />
    }
  ];
  return (
    <div className="joyride-start">
      <div className="joyride-header">
        <div
          style={{ fontWeight: 500 }}
          className="joyride-detail"
        >
          <span>{stepContents[currentStep - 1]?.title}</span>
        </div>
        <div className="close-btn" onClick={() => {
          helpers?.skip()
          setIsGuidedTourReplay(true);
          logClickButton('StoriezGuid skip');
        }}>
          <AiOutlineClose size={23} />
        </div>
      </div>
      <hr className="joyride-solid" />
      <div className={`joyride-main joyride-main-${currentStep} row`}>
        <div className="col-7 joyride-col">
          <ReactPlayer
            playing={true}
            width="100%"
            height="auto"
            autoPlay
            playsinline
            muted
            loop
            url={stepContents[currentStep - 1]?.url}
          />
        </div>
        <div className="col-5 joyride-col details-col">{stepContents[currentStep - 1]?.detail}</div>
      </div>
      <div className="joyride-footer">
        <div className="footer-actions">
          <button className="btn joyride-btn-back" onClick={onBack}>
            <div className="joyride-button-text">
              {tc({ key: 'tour:back' })}
            </div>
            <BsChevronLeft className='icon-prev'/>
          </button>
          {stepContents[currentStep - 1]?.action}
          <button className="btn joyride-btn-next" onClick={onNext}>
            <div className="joyride-button-text">
              {tc({ key: 'tour:next' })}
            </div>
            <BsChevronRight className='icon-next'/>
          </button>
        </div>
        <div className="progress-steps">
          <Stepper
            steps={[
              {
                step: 1
              },
              {
                step: 2
              },
              {
                step: 3
              },
              {
                step: 4
              }
            ]}
            activeStep={currentStep}
          />
        </div>
      </div>
      <style jsx>{`
        :global(.main-container) {
          width: 350px !important;
          max-width: 70% !important;
        }
      `}</style>
    </div>
  );
};

export default StepDetails;
