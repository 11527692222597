import { BsQuestionCircle } from 'react-icons/bs';
import { StoreHelpers } from 'react-joyride';
import { logClickButton } from '../../../../analytics';
import { useTextContent } from '../../hooks/tour';

const ButtonJoyride = ({
  helpers,
  btnType,
  nextLabel = 'next',
  skipLabel = 'Skip Tour'
}: {
  helpers: StoreHelpers;
  btnType?: string;
  nextLabel?: string;
  skipLabel?: string;
}) => (
  <div
    className="joyride-container buttonJoyride"
    
  >
    <button
      className="btn joyride-btn-next"
      onClick={() => {
        if (btnType === 'close') {
          helpers?.prev();
          helpers?.close();
          logClickButton('UserManageGuid close');
        } else {
          helpers?.next();
          logClickButton('UserManageGuid next');
        }
      }}
    >
      <span className="joyride-button-text">{nextLabel}</span>
    </button>
    <button
      className="btn joyride-btn-skip"
      onClick={() => {
        helpers?.skip();
        logClickButton('UserManageGuid skip');
      }}
    >
      <span className="joyride-button-text">{skipLabel}</span>
    </button>
  </div>
);
export const StepStart = ({ helpers }: { helpers: StoreHelpers }) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-title">
        <p style={{ marginBottom: '1.4rem' }}>
          <strong style={{ fontSize: '24px' }} className="joyride-detail">
            {tc({ key: 'tour:user_management' })}
          </strong>
        </p>
        <span
          style={{ fontSize: '1.1rem', fontWeight: 500 }}
          className="joyride-detail-title"
        >
          {tc({ key: 'tour:um_start' })}
        </span>
      </div>
      <hr className="joyride-solid" />
      <div style={{ margin: '0 40px' }} className="joyride-detail">
        {tc({ key: 'tour:are_you_ready' })}
      </div>
      <div className="joyride-title" />
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <span className="joyride-button-text">
          {tc({ key: 'tour:start_tour' })}
        </span>
      </button>
    </>
  );
};
export const StepInviteTeam = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:invite_team' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:invite_team_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepEmailAddress = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:email_address' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:email_address_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepRolePrivileges = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:role_privileges' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:role_privileges_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepAddTeamMember = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-step">{stepNo}</div>
      <div className="joyride-step">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:add_team_member' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:add_team_member_detail' })}
      </div>

      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepSendingInvite = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:sending_invite' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:sending_invite_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepUsers = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:users' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:users_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepJoiningStatus = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:joining_status' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:joining_status_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepUserPrivileges = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:user_privileges' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:user_privileges_detail_1' })}
        <br />
        <br />
        {tc({ key: 'tour:user_privileges_detail_2' })}
        <BsQuestionCircle size={16} color={'#fff'} />
        {tc({ key: 'tour:user_privileges_detail_3' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepDeleteAccount = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:delete_account' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:delete_account_detail' })}
        <br />
        <br />
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepSearchUsers = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:search_users' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:search_users_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepEnd = ({ helpers }: { helpers: StoreHelpers }) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-title">
        <p style={{ marginBottom: '1.4rem' }}>
          <strong style={{ fontSize: '24px' }} className="joyride-detail">
            {tc({ key: 'tour:great_work' })}
          </strong>
        </p>
        <span
          style={{ fontSize: '1.1rem', fontWeight: 500 }}
          className="joyride-detail-title"
        >
          {tc({ key: 'tour:um_great_work_1' })}
        </span>
      </div>
      <hr className="joyride-solid" />
      <div className="joyride-detail">
        {tc({ key: 'tour:um_great_work_2' })}
        <BsQuestionCircle size={16} color={'#fff'} />
        {tc({ key: 'tour:um_great_work_3' })}
      </div>
      <br />
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <span className="joyride-button-text">
          {tc({ key: 'tour:get_started' })}
        </span>
      </button>
    </>
  );
};
