import { BsQuestionCircle } from 'react-icons/bs';
import { StoreHelpers } from 'react-joyride';
import { logClickButton } from '../../../../analytics';
import { useTextContent } from '../../hooks/tour';

const ButtonJoyride = ({
  helpers,
  btnType,
  nextLabel = 'next',
  skipLabel = 'Skip Tour'
}: {
  helpers: StoreHelpers;
  btnType?: string;
  nextLabel?: string;
  skipLabel?: string;
}) => (
  <div
    className="joyride-container"
    style={{ paddingBottom: '5px', marginTop: '40px' }}
  >
    <button
      className="btn joyride-btn-next"
      onClick={() => {
        if (btnType === 'close') {
          helpers?.prev();
          helpers?.close();
          logClickButton('BrandTourGuid close');
        } else {
          logClickButton('BrandTourGuid Next');
          helpers?.next();
        }
      }}
    >
      <span className="joyride-button-text">{nextLabel}</span>
    </button>
    <button
      className="btn joyride-btn-skip"
      onClick={() => {
        logClickButton('BrandTourGuid Skip');
        helpers?.skip();
      }}
    >
      <span className="joyride-detail">{skipLabel}</span>
    </button>
  </div>
);
export const StepStart = ({ helpers }: { helpers: StoreHelpers }) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-title">
        <p style={{ marginBottom: '1.4rem' }}>
          <strong style={{ fontSize: '24px' }} className="joyride-detail">
            {tc({ key: 'tour:branding' })}
          </strong>
        </p>
        <span
          style={{ fontSize: '1.1rem', fontWeight: 500 }}
          className="joyride-detail-title"
        >
          {tc({ key: 'tour:branding_start' })}
        </span>
      </div>
      <hr className="joyride-solid" />
      <div style={{ margin: '0 40px' }} className="joyride-detail">
        {tc({ key: 'tour:are_you_ready' })}
      </div>
      <div className="joyride-title" />
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <span className="joyride-button-text">
          {tc({ key: 'tour:start_tour' })}
        </span>
      </button>
    </>
  );
};
export const StepMenuItemProfile = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font joyride-detail-title">
          {tc({ key: 'tour:brand_information' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:brand_information_detail' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepMenuItemTheme = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">{tc({ key: 'tour:brand_theme' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:brand_theme_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepMenuItemAssets = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">{tc({ key: 'tour:brand_assets' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:brand_assets_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepBrandInfo = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="joyride-detail-title">
          {tc({ key: 'tour:brand_information' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:brand_information_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepBrandLogo = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="joyride-detail">
          {tc({ key: 'tour:upload_brand_logo' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:upload_brand_logo_detail_l1' })}
        <br />
        {tc({ key: 'tour:upload_brand_logo_detail_l2' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepBrandTheme = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="joyride-detail">
          {tc({ key: 'tour:set_brand_colors' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:set_brand_colors_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepBrandCustomStyle = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="joyride-detail">
          {tc({ key: 'tour:set_brand_fonts' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:set_brand_fonts_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepBrandPreviewStyle = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="joyride-detail">
          {tc({ key: 'tour:preview_themes' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:preview_themes_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepOgImage = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">
          {tc({ key: 'tour:upload_thumbnail_image' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:upload_thumbnail_image_detail_1' })}
        <br />
        <br />
        {tc({ key: 'tour:upload_thumbnail_image_detail_2' })}
        <br />
        <br />
        {tc({ key: 'tour:upload_thumbnail_image_detail_3' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepMeetingBgLandscape = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">
          {tc({ key: 'tour:meet_cover_photo_landscape' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:meet_cover_photo_detail_1' })}
        <br />
        <br />
        {tc({ key: 'tour:meet_cover_photo_detail_2' })}
        <br />
        <br />
        {tc({ key: 'tour:meet_cover_photo_detail_3' })}
        <br />
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepMeetingBgPortrait = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">
          {tc({ key: 'tour:meet_cover_photo_portrait' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:meet_cover_photo_detail_1' })}

        <br />
        <br />
        {tc({ key: 'tour:meet_cover_photo_detail_mobile' })}

        <br />
        <br />
        {tc({ key: 'tour:meet_cover_photo_detail_3' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepIntroVideo = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">
          {tc({ key: 'tour:upload_meet_intro_video' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:meet_cover_photo_detail_1' })}
        <br />
        <br />
        {tc({ key: 'tour:upload_meet_video_detail_1' })}

        <br />
        <br />
        {tc({ key: 'tour:upload_meet_video_detail_2' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepOutroVideo = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">
          {tc({ key: 'tour:upload_meet_outro_video' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:meet_cover_photo_detail_1' })}
        <br />
        <br />
        {tc({ key: 'tour:upload_meet_outro_video_detail_1' })}

        <br />
        <br />
        {tc({ key: 'tour:upload_meet_outro_video_detail_2' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepIntroOutroVideoMobile = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">
          {tc({ key: 'tour:upload_meet_intro_outro_video' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:upload_meet_intro_outro_video_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepMeetMobile = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">
          {tc({ key: 'tour:upload_meet_cover_photo' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:upload_meet_cover_photo_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepEnd = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-title">
        <p style={{ marginBottom: '0.8rem' }}>
          <strong style={{ fontSize: '24px' }} className="joyride-detail">
            {tc({ key: 'tour:great_work' })}
          </strong>
        </p>
        <span
          style={{ fontSize: '1.0rem', fontWeight: 500 }}
          className="joyride-detail-title"
        >
          {tc({ key: 'tour:brand_great_work_detail_1' })}
        </span>
      </div>

      <hr className="joyride-solid" />
      <div className="joyride-detail">
        {tc({ key: 'tour:brand_great_work_detail_2' })}
        <BsQuestionCircle size={14} color={'#fff'} />
        {tc({ key: 'tour:brand_great_work_detail_3' })}
      </div>
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <span className="joyride-button-text">
          {tc({ key: 'tour:get_started' })}
        </span>
      </button>
    </>
  );
};
export const StepMenuItemBrandMobile = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">{tc({ key: 'tour:brand_theme' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:brand_theme_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepColorFontMobile = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <strong className="font">{tc({ key: 'tour:set_colors_fonts' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:set_colors_fonts_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
