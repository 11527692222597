import React from 'react';
import { BiCalendar, BiColumns } from 'react-icons/bi';
import { BsImages, BsX } from 'react-icons/bs';
import { CgTemplate } from 'react-icons/cg';
import { FiUsers } from 'react-icons/fi';
import {
  MdExitToApp,
  MdMenu,
  MdStoreMallDirectory,
  MdSubtitles
} from 'react-icons/md';
import { RiTeamFill, RiUser3Line } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../analytics';
import { HUB_USER_PERMISSION } from '../../interfaces';
import { DID_CLICK_HUB_MENU } from '../../utils/constants';
import {
  shouldShowAdmin,
  shouldShowCalendar,
  shouldShowGallery,
  shouldShowInvites,
  shouldShowOpenSalesLoft,
  shouldShowOpenVB,
  shouldShowProfile,
  shouldShowTeams
} from '../../utils/entitlements';
// import { generateV4UUID } from '../../utils/identityGenerator';
import { PageView } from '../../utils/pageView';
import {
  isInspifyTestBrand,
  shouldShowStorybooks
} from '../utils/hubEntitlements';
import { hubNavTo } from '../utils/hubPagesRoute';
import {
  getLoftUrlByStoreId,
  // getVBUrl,
  getVBUrlByStoreId
} from './../../config';
import { mobileBreakPoint } from './BrandStyle';
import {
  DeviceIcon,
  DeviceMonitorIcon,
  IconLibrary,
  IconStorybook,
  UserManagementIcon
} from './Common/HubIcons';
import { HubContext } from './HubContext';
import { AssetLibraryIcon, BrandingIcon } from './Common/HubIcons';
import { actionSignOutAsync } from '../../redux/advisorHubAsyncActions';
import { FaDollarSign } from 'react-icons/fa';

const Menu = ({
  storeId,
  theme = 'dark'
}: {
  storeId?: string;
  theme?: 'dark' | 'light' | 'transparent';
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const {
    brandId,
    isAdmin,
    isEmbeddedInSsp,
    user,
    page,
    entitlements,
    meetUser,
    userRoles,
    chooseCheckPermissionResult,
    checkUserPermissions,
    isStudioOwnerUser,
    checkUserHasEntitlement
  } = React.useContext(HubContext);
  // const generateMeetingUrl = () => {
  //   const storeUrl = getVBUrlByStoreId(storeId) || getLoftUrlByStoreId(storeId);
  //   return `${storeUrl}?mode=cockpit&role=advisor&meeting=${generateV4UUID()}`;
  // };

  const handleClick = (menu: string, url?: string) => {
    logEvent(DID_CLICK_HUB_MENU, DID_CLICK_HUB_MENU, { menu, url });
  };
  const handleRedirect = (redirectTo: PageView) => {
    if (redirectTo === page) return;
    setShow(false);
    hubNavTo(redirectTo);
  };

  return (
    <>
      <button className="menu-nav btn" onClick={() => setShow(!show)}>
        {show ? <BsX /> : <MdMenu />}
      </button>
      <div className={`nav-container ${show ? 'show' : ''}`}>
        {shouldShowOpenSalesLoft(
          brandId,
          storeId,
          isEmbeddedInSsp,
          entitlements?.meetOnly,
          user?.userType
        ) && (
          <a
            href={getLoftUrlByStoreId(storeId)}
            target="_blank"
            className="nav-btn btn btn-visit-loft"
            onClick={() =>
              handleClick('Open Sales Loft', getLoftUrlByStoreId(storeId))
            }
          >
            <span>Open Sales Loft</span>
            <MdSubtitles />
          </a>
        )}
        {shouldShowOpenVB(
          brandId,
          storeId,
          isEmbeddedInSsp,
          entitlements?.meetOnly,
          user?.userType
        ) && (
          <a
            href={getVBUrlByStoreId(storeId)}
            target="_blank"
            className="nav-btn btn btn-visit-vb"
            onClick={() =>
              handleClick('Open Virtual Boutique', getVBUrlByStoreId(storeId))
            }
          >
            <span>Open Virtual Boutique</span>
            <MdStoreMallDirectory />
          </a>
        )}

        {chooseCheckPermissionResult(
          shouldShowCalendar(meetUser?.permissions, user?.userType),
          checkUserPermissions(HUB_USER_PERMISSION.CALENDAR)
        ) && (
          <>
            <button
              onClick={() => {
                handleRedirect(PageView.HUB_SESSION_OVERVIEW);
                handleClick('Session Management');
              }}
              className="nav-btn btn btn-session"
            >
              <span>Calendar</span>
              <BiCalendar />
            </button>
            <button
              onClick={() => {
                handleRedirect(PageView.HUB_MEETING_ROOMS);
                handleClick('Meeting Rooms');
              }}
              className="nav-btn btn btn-room"
            >
              <span>Meeting Rooms</span>
              <RiTeamFill />
            </button>
          </>
        )}
        {chooseCheckPermissionResult(
          shouldShowInvites(user?.userType, entitlements?.meetOnly),
          checkUserPermissions(HUB_USER_PERMISSION.NUDGE)
        ) && (
          <button
            onClick={() => {
              handleRedirect(PageView.HUB_NUDGES);
              handleClick('Invites');
            }}
            className="nav-btn btn btn-nudges"
          >
            <span>Nudges</span>
            <BiColumns />
          </button>
        )}

        {checkUserHasEntitlement(HUB_USER_PERMISSION.DEVICES_MANAGEMENT) && (
          <button
            onClick={() => {
              handleRedirect(PageView.HUB_DEVICES_MANAGEMENT);
              handleClick('Devices');
            }}
            className="nav-btn btn btn-devices"
          >
            <span>Devices</span>
            <DeviceIcon />
          </button>
        )}
        {checkUserHasEntitlement(HUB_USER_PERMISSION.DEVICES_MONITORING) && (
          <button
            onClick={() => {
              handleRedirect(PageView.HUB_DEVIVES_MONITORING);
              handleClick('Device-Monitoring');
            }}
            className="nav-btn btn btn-devices"
          >
            <span>Device Monitoring</span>
            <DeviceMonitorIcon />
          </button>
        )}

        {chooseCheckPermissionResult(
          shouldShowStorybooks({
            storeId,
            brandId,
            permissions: meetUser?.permissions,
            userType: user?.userType
          }),
          checkUserPermissions(HUB_USER_PERMISSION.STORYBOOK)
        ) && (
          <button
            onClick={() => {
              handleRedirect(PageView.HUB_STORYBOOK);
              handleClick('Storybook');
            }}
            className="nav-btn btn btn-hub-storybook"
          >
            <span>Storybook</span>
            <IconStorybook />
          </button>
        )}
        {chooseCheckPermissionResult(
          shouldShowStorybooks({
            storeId,
            brandId,
            permissions: meetUser?.permissions,
            userType: user?.userType
          }),
          checkUserPermissions(HUB_USER_PERMISSION.SCENE)
        ) && (
          <button
            onClick={() => {
              //NOTE: ORIGINAL NAME - need to rename later
              handleRedirect(PageView.HUB_SCENE);
              handleClick('Library');
            }}
            className="nav-btn btn btn-hub-library"
          >
            <span>Scene</span>
            <IconLibrary />
          </button>
        )}
        {(isInspifyTestBrand(brandId) ||
          checkUserPermissions(HUB_USER_PERMISSION.SCENE_TEMPLATE)) && (
          <button
            onClick={() => {
              handleRedirect(PageView.HUB_SCENE_TEMPLATE);
              handleClick('Scene Template');
            }}
            className="nav-btn btn btn-hub-library-template"
          >
            <span>Scene Template</span>
            <CgTemplate />
          </button>
        )}
        {chooseCheckPermissionResult(
          shouldShowGallery(user?.userType, entitlements?.meetOnly),
          checkUserPermissions(HUB_USER_PERMISSION.GALLERY)
        ) && (
          <button
            onClick={() => {
              handleRedirect(PageView.HUB_GALLERY);
              handleClick('Gallery');
            }}
            className="nav-btn btn btn-hub-gallery"
          >
            <span>Gallery</span>
            <BsImages />
          </button>
        )}
        {checkUserPermissions(HUB_USER_PERMISSION.ASSET_LIBRARY) && (
          <button
            className="nav-btn btn btn-brand-settings"
            onClick={() => {
              handleRedirect(PageView.HUB_ASSET_LIBRARY);
              handleClick('Asset Library');
            }}
          >
            <span>Asset Library </span> <AssetLibraryIcon />
          </button>
        )}

        {chooseCheckPermissionResult(
          shouldShowAdmin(meetUser?.permissions, user?.userType, isAdmin),
          checkUserPermissions(HUB_USER_PERMISSION.USER_MANAGEMENT)
        ) && (
          <button
            className="nav-btn btn btn-users"
            onClick={() => {
              handleRedirect(PageView.HUB_USERMANAGEMENT);
              handleClick('User management');
            }}
          >
            <span>User Management</span> <UserManagementIcon />
          </button>
        )}

        {chooseCheckPermissionResult(
          shouldShowTeams({
            brandId,
            permissions: meetUser?.permissions,
            userType: user?.userType,
            storeId
          }),
          checkUserPermissions(HUB_USER_PERMISSION.TEAM)
        ) && (
          <button
            className="nav-btn btn btn-teams"
            onClick={() => {
              handleRedirect(PageView.HUB_TEAMS);
              handleClick('Teams');
            }}
          >
            <span>Teams</span> <FiUsers />
          </button>
        )}
        {chooseCheckPermissionResult(
          shouldShowProfile(meetUser?.permissions, user?.userType),
          checkUserPermissions(HUB_USER_PERMISSION.PROFILE)
        ) && (
          <button
            className="nav-btn btn btn-profile"
            onClick={() => {
              handleRedirect(PageView.HUB_ACCOUNTSETTINGS);
              handleClick('Profile');
            }}
          >
            <span>Profile</span> <RiUser3Line />
          </button>
        )}

        {checkUserPermissions(HUB_USER_PERMISSION.BRANDING) && (
          <button
            className="nav-btn btn btn-brand-settings"
            onClick={() => {
              handleRedirect(PageView.HUB_BRANDING);
              handleClick('Branding');
            }}
          >
            <span>Branding </span> <BrandingIcon />
          </button>
        )}
        {(isAdmin ||
          userRoles?.some(
            (r) => r.name.includes('ADMIN') || r.name.includes('OWNER')
          ) ||
          isStudioOwnerUser()) && (
          <button
            className="nav-btn btn btn-brand-settings"
            onClick={() => {
              handleRedirect(PageView.HUB_ACCOUNT);
              handleClick('Account');
            }}
          >
            <span>Account </span>
            <FaDollarSign />
          </button>
        )}

        {!isEmbeddedInSsp && (
          <button
            className="nav-btn btn btn-logout"
            onClick={() => {
              dispatch(actionSignOutAsync());
              handleClick('Logout');
            }}
          >
            <span>Logout</span> <MdExitToApp />
          </button>
        )}
      </div>
      {show && (
        <div className="nav-backdrop" onClick={() => setShow(!show)}></div>
      )}

      <style jsx>{`
        .menu-nav {
          color: ${theme === 'dark' ? '#fff' : '#000'};
          font-size: 22px;
          outline: none;
          box-shadow: none;
        }
        :global(.touch) .menu-nav {
          font-size: 40px;
        }

        :global(.ssp) .menu-nav {
          font-size: 50px;
          line-height: 40px;
        }
        .nav-close {
          display: block;
          float: right;
          margin-top: 6px;
          background: transparent;
          color: #666;
        }
        .nav-close :global(svg) {
          width: 40px;
          height: auto;
        }

        .nav-container {
          position: fixed;
          right: -300px;
          left: auto;
          top: 50px;
          bottom: 0;
          text-align: left;
          width: 250px;
          z-index: 41;
          overflow-y: auto;
          color: #fff;
          opacity: 0;
          transition: all 0.2s ease-in-out;
          color: #fff;
        }

        :global(.ssp) .nav-container {
          width: 450px;
          top: 80px;
          right: -600px;
        }
        .nav-btn {
          display: block;
          color: #fff;
          text-align: left;
          padding: 15px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          width: 100%;
          border-radius: 0;
          display: flex;
          background: rgba(0, 0, 0, 0.8);
          justify-content: space-between;
        }
        :global(.touch) .nav-btn {
          padding: 20px 15px;
        }
        :global(.ssp) .nav-btn {
          padding: 30px 20px;
          font-size: 1.4em;
        }
        .nav-btn :global(svg) {
          width: 20px;
          height: auto;
        }
        :global(.ssp) .nav-btn :global(svg) {
          width: 40px;
          height: auto;
        }
        .nav-btn:hover {
          background: #000;
        }

        .btn-users :global(svg) {
          width: 22px;
          height: 20px;
        }

        .nav-container.show {
          transform: translateX(-300px);
          opacity: 1;
        }

        :global(.ssp) .nav-container.show {
          transform: translateX(-600px);
        }

        .nav-backdrop {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
        .btn-hub-library :global(svg) {
          width: 25px;
          height: auto;
          margin-right: -3px;
        }

        @media (min-width: ${mobileBreakPoint}px) {
          .nav-container {
            top: 70px;
          }
        }
      `}</style>
    </>
  );
};

export default Menu;
