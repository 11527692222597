import React from 'react';
import ReactJoyride, {
  CallBackProps,
  STATUS,
  StoreHelpers
} from 'react-joyride';
import { IHubTourState } from '..';
import { getCurrentUserInfo, updateUserManagementGuideTour } from '../../../../clientSideServices/users';
import {
  StepStart,
  StepEnd,
  StepInviteTeam,
  StepEmailAddress,
  StepRolePrivileges,
  StepAddTeamMember,
  StepSendingInvite,
  StepUsers,
  StepJoiningStatus,
  StepUserPrivileges,
  StepDeleteAccount,
  StepSearchUsers
} from './UserManagementStep';

const UserManagementTour = ({onClose, showDesignerTour}: {onClose?: () => void, showDesignerTour?: number | null}) => {
  const [step, setStep] = React.useState({ no: 0, size: 10, mSize: 10 });
  const [helpers, setHelpers] = React.useState<StoreHelpers>();
  const [state, setState] = React.useState<IHubTourState>({
    run: true,
    steps: [
      {
        content: <StepStart helpers={helpers} />,
        placement: 'center',
        target: 'body'
      }
    ]
  });

  React.useEffect(() => {
    if(helpers?.info()?.action === 'close') helpers?.reset(true)
  }, [showDesignerTour])

  React.useEffect(() => {
    if (!state.run && onClose) {
      onClose()
    }
  }, [state.run])

  React.useEffect(() => {
    let fetching;
    if (step.no > 0) {
      getCurrentUserInfo().then((userInfo) => {
        if (userInfo?.attributes?.['custom:gt_user_management'] !=='skipped') {
          updateUserManagementGuideTour("skipped")
        }
      });
    }
    if (helpers && step.no === 1) {
      fetching = setInterval(() => {
        const elements = document.querySelectorAll(
          '.user-block .form-control-email'
        );
        if (elements?.length > 0) {
          helpers.next();
          const steps = state.steps;
          setState({ ...state, steps: steps });
          clearInterval(fetching);
          setTimeout(() => helpers.open(), 100);
        }
      }, 1000);
    }
    if (helpers && step.no === 5) {
      const collection = document.getElementsByClassName('team-user-list');
      if (collection?.[0]?.scrollTop) collection[0].scrollTop = 0;
      fetching = setInterval(() => {
        const elements = document.querySelectorAll(
          '.user-block .form-control-email'
        );
        if (elements?.length === 0) {
          helpers.next();
          const steps = state.steps;
          setState({ ...state, steps: steps });
          clearInterval(fetching);
          setTimeout(() => helpers.open(), 100);
        }
      }, 1000);
    }
    return () => {
      fetching && clearInterval(fetching);
    };
  }, [helpers, step]);
  const getHelpers = (helpers: StoreHelpers) => {
    setHelpers(helpers);

    setState({
      ...state,
      steps: [
        {
          content: <StepStart helpers={helpers} />,
          placement: 'center',
          target: 'body'
        },
        {
          content: (
            <StepInviteTeam helpers={helpers} stepNo={`1 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'left-start',
          spotlightPadding: 10,
          target: '.btn-dark'
        },
        {
          content: (
            <StepEmailAddress helpers={helpers} stepNo={`2 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 5,
          target: '.user-block .form-control-email'
        },
        {
          content: (
            <StepRolePrivileges helpers={helpers} stepNo={`3 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 5,
          target: '.user-block .role-select'
        },
        {
          content: (
            <StepAddTeamMember helpers={helpers} stepNo={`4 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 4,
          target: '.btn-address'
        },
        {
          content: (
            <StepSendingInvite helpers={helpers} stepNo={`5 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'top',
          spotlightPadding: 10,
          target: '.model-footer .btn-dark'
        },
        {
          content: <StepUsers helpers={helpers} stepNo={`6 / ${step.size}`} />,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 8,
          target: '.user-detail .user-title'
        },
        {
          content: (
            <StepJoiningStatus helpers={helpers} stepNo={`7 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 14,
          target: '.user-detail .join-date'
        },
        {
          content: (
            <StepUserPrivileges helpers={helpers} stepNo={`8 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'left',
          spotlightPadding: 14,
          target: '.user-detail .user-role .role-select'
        },
        {
          content: (
            <StepDeleteAccount helpers={helpers} stepNo={`9 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'left',
          spotlightPadding: 14,
          target: '.user-detail .user-role .btn-round'
        },
        {
          content: (
            <StepSearchUsers helpers={helpers} stepNo={`10 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 10,
          target: '.search-bar .search-user'
        },
        {
          content: <StepEnd helpers={helpers} />,
          placement: 'center',
          target: 'body'
        }
      ]
    });
  };
  const getTop = (pos: number) => {
    switch (step.no) {
      case 2:
        return `top: ${50 + pos}px !important;`;
      case 3:
        return `top: ${70 + pos}px !important;`;
      case 4:
        return `top: ${100 + pos}px !important;`;
      default:
        return '';
    }
  };
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    setStep({ ...step, no: index });
    if (finishedStatuses.includes(status)) {
      setState({ ...state, run: false });
    }
  };
  const { run, steps } = state;
  return (
    <>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        disableOverlayClose={true}
        disableScrolling={true}
        styles={{
          beacon: {
            display: 'none'
          },
          buttonClose: {
            display: 'none'
          },
          buttonNext: {
            display: 'none'
          },
          buttonBack: {
            display: 'none'
          },
          options: {
            backgroundColor: '#000000',
            width: step.no === 0 ? 500 : 450,
            zIndex: 10000,
            arrowColor: '#000000'
          }
        }}
      />
      <style jsx global>{`
        .joyride-title {
          margin-top: 30px;
        }
        .joyride-solid {
          border-top: 1px solid #896896;
        }
        .joyride-detail {
          font-size: 0.95rem;
          color: #ffffff;
        }
        .joyride-detail-title {
          font-size: 1.1rem;
          font-weight: 500;
          color: #ffffff;
        }
        .joyride-button-text {
          font-size: 1.1rem;
          color: #fff;
          padding: 0 12px;
          font-weight: 500;
        }
        .joyride-btn-start {
          background: #896896;
          border-radius: 20px;
          margin-top: 20px;
        }
        .joyride-container {
          font-size: 0.95rem;
          text-align: left;
          margin-bottom: 10px;
          color: #ffffff;
        }
        .joyride-btn-next {
          background: #896896;
          border-radius: 20px;
          float: right;
          padding: 5px 10px;
          margin-top: 3px;
          color: #896896;
        }
        .joyride-btn-skip {
          background: #c4c4c400;
          border-radius: 20px;
          float: right;
          color: #ffffff;
        }
        .joyride-step {
          font-size: 0.95rem;
          text-align: left;
          margin-bottom: 10px;
          color: #ffffff;
        }
        .buttonJoyride {
          padding-bottom: 5px;
          margin-top: 50px;
        }
        @media (max-height: 700px) {
          .joyride-step {
            margin-bottom: 0px;
            transform: translateY(-10px);
            font-size: 0.95rem;
            text-align: left;
            margin-bottom: 0px;
            color: #ffffff;
          }
          .buttonJoyride {
            padding-bottom: 5px;
            margin-top: 0px;
          }
        }
        @media (max-height: 600px) {
          .__floater__open {
            ${getTop(-5)}
          }
          .PopupContainer {
            padding-top: 0px !important;
          }
        }
        @media (max-height: 500px) {
          .__floater__open {
            ${getTop(20)}
          }
          .PopupContainer {
            padding-top: 0px !important;
          }
        }
      `}</style>
    </>
  );
};

export default UserManagementTour;
