import React from 'react';
import ReactJoyride, {
  CallBackProps,
  STATUS,
  StoreHelpers
} from 'react-joyride';
import { IHubTourState } from '..';
import { getCurrentUserInfo, updateUserAssetLibGuideTour } from '../../../../clientSideServices/users';
import { isMobileViewport } from '../../../../utils/deviceDetector';
import {
  StepAssetDetailsStep1,
  StepAssetDetailsStep2,
  StepCategoryOverview,
  StepCreateCategoryFirstStep,
  StepCreateCategorySecondStep,
  StepEnd,
  StepSearchArea,
  StepSelectionModes,
  StepStart,
  StepSubCategory,
  StepUploadFilesNotes,
  StepUploadFilesStep1,
  StepUploadFilesStep2,
} from './ALDesignerSteps';

const ALDesignerTour = ({onClose}: {onClose?: () => void}) => {

  const isMobileView = isMobileViewport();

  const [stepNo, setStepNo] = React.useState(0);
  const [helpers, setHelpers] = React.useState<StoreHelpers>();
  const [state, setState] = React.useState<IHubTourState>({
    run: true,
    steps: [
      {
        content: <StepStart helpers={helpers} />,
        placement: 'center',
        target: 'body'
      }
    ]
  });

  React.useEffect(() => {
    if (!state.run) {
      const categoriesContainer = document.querySelector('.categories-container') as HTMLElement | null;
      if (categoriesContainer) {
        categoriesContainer.style?.removeProperty('overflow')
      }
    }
    if (!state.run && onClose) {
      onClose()
    }
  }, [state.run])
  React.useEffect(() => {
    let fetching;
    if (stepNo > 0) {
      getCurrentUserInfo().then((userInfo) => {
        if (userInfo?.attributes?.['custom:gt_asset_library'] !=='skipped') {
          updateUserAssetLibGuideTour("skipped")
        }
      });
    }
    if(helpers && !isMobileView) {
      switch (stepNo) {
        case 2: {
          const element = document.querySelector('.categories-container .parent-category');
          if (element) {
            const item = element.querySelector('.expand-btn') as HTMLElement | null
            if(item) {
              item.click()
            }
            setTimeout(() => element.scrollIntoView({behavior: 'smooth', block: 'start', inline : 'nearest'}), 100);
            helpers.open()
          }
          break;
        }
        case 3: {
          const element = document.querySelector('.form__edit-category');
          if (element) {
            const item = element.querySelector('button') as HTMLElement | null
            if(item) {
              item.click()
            }
            helpers.open()
          }
          break;
        }
        case 4: {
          const element = document.querySelector('.block__create-category button') as HTMLElement | null;
          if (element) {
            helpers.go(4)
            element.click()
            setTimeout(() => helpers.open(), 100);
          }
          break;
        }
        case 5: {
          const element = document.querySelector('.form__edit-category button') as HTMLElement | null;
          if (element) {
            element.click()
            setTimeout(() => helpers.open(), 100);
          }
          break;
        }
        case 7: {
          const element = document.querySelector('.asset-library-header .btn-upload') as HTMLElement | null;
          if (element) {
            helpers.go(7)
            element.click()
            setTimeout(() => helpers.open(), 100);
          }
          break;
        }
        case 8: {
          const element = document.querySelector('.close-container button') as HTMLElement | null;
          if (element) {
            helpers.go(8)
            element.click()
            setTimeout(() => helpers.open(), 100);
        }
        break;
      }
      case 9: {
        const element = document.querySelector('.card-asset') as HTMLElement | null;
        if (element) {
          helpers.go(9)
          element.click()
          setTimeout(() => helpers.open(), 100);
        }
        break;
      }
      case 11: {
        helpers.go(11)
        setTimeout(() => helpers.open(), 100);
        break;
      }
    }
  }
    return () => {
      fetching && clearInterval(fetching);
    };
  }, [helpers, stepNo]);

  const getHelpers = (helpers: StoreHelpers) => {
    setHelpers(helpers);
    setState({
      ...state,
      steps: [
        {
          content: <StepStart helpers={helpers} />,
          placement: 'center',
          target: 'body'
        },
        {
          content: <StepCategoryOverview helpers={helpers} total={11} current={1}/>,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 0,
          target: '.categories-container',
        },
        {
          content: <StepSubCategory helpers={helpers} total={11} current={2}/>,
          floaterProps: {
            disableAnimation: true,
          },
          placement: 'right',
          spotlightPadding: 0,
          target: '.categories-container .parent-category'
        },
        {
          content: <StepCreateCategoryFirstStep helpers={helpers} total={11} current={3}/>,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'top',
          spotlightPadding: 0,
          target: '.block__create-category'
        },
        {
          content: <StepCreateCategorySecondStep helpers={helpers} total={11} current={4}/>,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 0,
          target: '.form__edit-category'
        },
        {
          content: <StepUploadFilesStep1 helpers={helpers} total={11} current={5}/>,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'bottom',
          spotlightPadding: 5,
          target: '.asset-library-header .btn-upload'
        },
        {
          content: <StepUploadFilesStep2 helpers={helpers} total={11} current={6}/>,
          floaterProps: {
            disableAnimation: true,
            placement: 'right',
          },
          isFixed: true,
          placement: 'right',
          spotlightPadding: -2,
          styles: {
            tooltip: {
              position: 'absolute',
              top: '-25vh',
              left: -300
            },
          },
          target: '.drop-zone .list-assets',
          
        },
        {
          content: <StepUploadFilesNotes helpers={helpers} total={11} current={7}/>,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 5,
          target: '.popup-content .upload-zone',
        },
        {
          content: <StepSelectionModes helpers={helpers} total={11} current={8}/>,
          floaterProps: {
            disableAnimation: true
          },
          styles: {
            tooltip: {
              position: 'absolute',
              top: '-35vh',
              left: -300
            },
          },
          placement: 'right',
          spotlightPadding: -2,
          target: '.drop-zone .list-assets'
        },
        {
          content: <StepAssetDetailsStep1 helpers={helpers} total={11} current={9}/>,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'left',
          spotlightPadding: 5,
          target: '.selected-asset-container .update-form .update-form__actions'
        },
        {
          content: <StepAssetDetailsStep2 helpers={helpers} total={11} current={10}/>,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'left',
          spotlightPadding: 15,
          target: '.selected-asset-container .update-form .form-selected-asset'
        },
        {
          content: <StepSearchArea helpers={helpers} total={11} current={11} />,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'bottom',
          spotlightPadding: 5,
          target: '.asset-library-header .search-block'
        },
        {
          content: <StepEnd helpers={helpers} />,
          placement: 'center',
          target: 'body'
        }
      ]
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    setStepNo(index);
    if (finishedStatuses.includes(status)) {
      setState({ ...state, run: false });
    }
  };
  const { run, steps } = state;
  return (
    <>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={false}
        steps={steps}
        disableOverlayClose={true}
        styles={{
          beacon: {
            display: 'none'
          },
          buttonClose: {
            display: 'none'
          },
          buttonNext: {
            display: 'none'
          },
          buttonBack: {
            display: 'none'
          },
          options: {
            backgroundColor: '#000000',
            width: stepNo == 0 || stepNo == 8 ? 500 : 370,
            zIndex: 10000,
            arrowColor: '#000000'
          }
        }}
      />
      <style jsx global>{`
        #react-joyride-step-6 .__floater__arrow{
          left: -315px !important;
          top: calc(-25vh + 125px) !important;
        }
        #react-joyride-step-8 .__floater__arrow {
          left: -315px !important;
          top: calc(-35vh + 220px) !important;
        }
        :global(.guided-tour-search) {
          display: ${stepNo===11 ? 'flex' : 'none'};
        }
        .overlay-drag-content {
          display: ${stepNo === 6 ? 'flex !important' : 'none'};
        }
        .list-assets {
          opacity: ${stepNo === 6 ? '0.5' : '1'};
        }
        .joyride-sub-note {
          font-size: 14px;
        }
        .joyride-sub-note ul {
          padding: 0 30px;
        }
        .react-joyride__spotlight {
          border-radius: 15px !important;
        }
        .react-joyride__tooltip {
          padding: 20px 15px 0 15px !important;
          min-width: 500px;
          border-radius: 15px !important;
          box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
        }
        .joyride-solid {
          border-top: 1px solid #896896;
        }
        .joyride-detail {
          font-size: 16px;
          color: #ffffff;
        }
        .joyride-detail svg {
          margin: 0 7px;
        }
        .joyride-step-progress {
          font-size: 16px;
          color: #FFF;
          text-align: left;
          margin-bottom: 20px;
        }
        .joyride-title {
          font-weight: 500;
          font-size: 22px;
          color: #FFF;
          text-align: left;
          margin-bottom: 20px;
        }
        .joyride-start {
          padding: 0 30px;
        }
        .joyride-start  .joyride-title, .joyride-end  .joyride-title{
          text-align: center !important;
        }
        .joyride-button-text {
          font-size: 22px;
          color: #fff;
          font-weight: 500;
        }
        .joyride-footer-actions {
          display: flex;
          justify-content: right;
        }
        .joyride-btn-start {
          background: #896896;
          border-radius: 34px;
          margin-top: 20px;
          padding: 3px 15px;
        }
        .joyride-container {
          font-size: 16px;
          text-align: left;
          padding-right: 20px;
          margin-bottom: 10px;
          color: #ffffff;
        }
        .joyride-btn-next {
          background: #896896;
          border-radius: 34px;
          float: right;
          padding: 3px 15px;
          margin-top: 3px;
          color: #896896;
        }
        .joyride-btn-skip {
          background: #c4c4c400;
          border-radius: 34px;
          float: right;
          padding: 3px 15px;
          margin-top: 3px;
          color: #ffffff;
        }
      `}</style>
    </>
  );
};

export default ALDesignerTour;
