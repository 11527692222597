import React from 'react';

export interface Step {
  step: number;
  label?: string;
}

const Stepper = ({
  steps = [],
  activeStep = 0,
}: {
  steps: Step[];
  activeStep: number;
}) => {
  const totalSteps = steps.length;
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <>
      <div className="main-container">
        <div className="step-container">
          {steps.map(({ step, label }, index) => (
            <div className="step-wrapper" key={step || index}>
              <div
                className={`step-style ${
                  (activeStep > step || step === 1)
                    ? 'step-completed'
                    : activeStep === step
                    ? 'step-active'
                    : ''
                }`}
              >
                <div className="step-count">{step}</div>
              </div>
              <div className="steps-label-container">
                <div className="step-label" key={step}>
                  {label}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        .main-container {
          width: 100%;
          max-width: 600px;
          margin: 0 auto;
          padding: 0 16px;
        }
        .step-container {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
          position: relative;
        }
        .step-container:before {
          content: '';
          position: absolute;
          background: #3d3d3d;
          height: 3px;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }

        .step-container:after {
          content: '';
          position: absolute;
          background: #8F6599;
          height: 3px;
          width: ${width};
          top: 50%;
          transition: 0.4s ease;
          transform: translateY(-50%);
          left: 0;
        }
        .step-wrapper {
          position: relative;
          z-index: 1;
        }
        .step-style {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #3d3d3d;
          color: #000;
          font-weight: 500;
          transition: 0.4s ease;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .step-active {
          background: #ffffff;
          color: #8f6599;
        }
        .step-completed {
          background: #8f6599;
          color: #ffffff;
        }
        .step-count {
          font-size: 16px;
          line-height: 16px;
        }

        @media (max-width: 600px) {
          .step-count,
          .step-label {
            font-size: 14px;
          }
        }
        .steps-label-container {
          position: absolute;
          top: 66px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .step-label {
          font-size: 19px;
          color: #8F6599;
        }
      `}</style>
    </>
  );
};

export default Stepper;
