import React from 'react';
import ReactJoyride, {
  CallBackProps,
  STATUS,
  StoreHelpers
} from 'react-joyride';
import { IHubTourState } from '..';
import { getCurrentUserInfo, updateUserStoriezGuideTour } from '../../../../clientSideServices/users';
import StepDetails from './StoriezDetailStep';
import StepEnd from './StoriezEndStep';
import StepStart from './StoriezStartStep';

const StoriezPopup = ({onClose}: {onClose?: () => void}) => {

  const [stepNo, setStepNo] = React.useState(0);
  const [helpers, setHelpers] = React.useState<StoreHelpers>();
  const [state, setState] = React.useState<IHubTourState>({
    run: true,
    steps: [
      {
        content: <StepStart helpers={helpers} />,
        placement: 'center',
        floaterProps: {
          disableAnimation: true
        },
        target: 'body'
      }
    ]
  });
  React.useEffect(() => {
    if (!state.run && onClose) {
      onClose()
    }
  }, [state.run])
  React.useEffect(() => {
    let fetching;
    if (stepNo > 0) {
      getCurrentUserInfo().then((userInfo) => {
        if (userInfo?.attributes?.['custom:gt_storiez'] !=='skipped') {
          updateUserStoriezGuideTour("skipped")
        }
      });
    }
    return () => {
      fetching && clearInterval(fetching);
    };
  }, [stepNo]);

  const getHelpers = (helpers: StoreHelpers) => {
    setHelpers(helpers);
    setState({
      ...state,
      steps: [
        {
          content: <StepStart helpers={helpers} />,
          placement: 'center',
          floaterProps: {
            disableAnimation: true
          },
          target: 'body',
        },
        {
          content: <StepDetails helpers={helpers} />,
          placement: 'center',
          floaterProps: {
            disableAnimation: true
          },
          target: 'body'
        },
        {
          content: <StepEnd helpers={helpers} />,
          placement: 'center',
          floaterProps: {
            disableAnimation: true
          },
          target: 'body'
        },
      ]
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    setStepNo(index);
    if (finishedStatuses.includes(status)) {
      setState({ ...state, run: false });
    }
  };
  const { run, steps } = state;
  return (
    <>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        scrollToFirstStep={true}
        showSkipButton={false}
        hideCloseButton={true}
        hideBackButton={true}
        steps={steps}
        disableOverlayClose={true}
        styles={{
          beacon: {
            display: 'none'
          },
          buttonNext: {
            display: 'none'
          },
          options: {
            backgroundColor: '#000000',
            width: stepNo == 0 || stepNo == 8 ? 500 : 370,
            zIndex: 10000,
            arrowColor: '#000000'
          }
        }}
      />
      <style jsx global>{`
        .react-joyride__overlay {
          height: 100% !important;
        }
        :global(.layout > .content-wrapper) {
          min-height: unset !important;
        }
        .react-joyride__tooltip {
          padding: 0 !important;
          min-width: 600px;
          border-radius: 15px !important;
          box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
          max-height: 95vh;
          overflow: auto;
          overflow-x: hidden;
        }
        #react-joyride-step-0 .joyride-header .joyride-detail {
          font-size: 40px;
          font-weight: 600;
        }
        #react-joyride-step-1 .react-joyride__tooltip {
          max-width: 1237px !important;
          width: 80vw !important;
        }
        .details-col {
          padding-left: 35px;
        }
        .joyride-actions {
          display: inline-grid;
          margin-bottom: 30px;
        }
        .react-joyride__tooltip>div>div{
          padding: 0 !important;
        }
        .joyride-solid {
          border-top: 1px solid #896896;
          margin: 0 !important;
        }
        .joyride-header .joyride-detail{
          font-size: 20px;
        }
        .storiez_details__list {
          margin-bottom: 15px;
        }
        .close-btn {
          color: #FFFFFF;
          position: absolute;
          top: 10px;
          right: 20px;
          padding: 5px;
          cursor: pointer;
        }
        .joyride-header {
          padding: 10px 40px;
        }
        #react-joyride-step-2 .joyride-header {
          padding: 0;
        }
        #react-joyride-step-2 .close-btn {
          top: 1.5rem;
          right: 1.5rem;
        }
        .joyride-footer {
          padding: 10px 30px;
        }
        .joyride-footer .joyride-solid {
          margin: 1rem !important;
        }
        .joyride-main {
          padding: 30px 0;
          margin-bottom: 10px;
          min-height: 500px;
        }
        .joyride-detail {
          font-size: 16px;
          color: #ffffff;
        }
        .joyride-title {
          font-weight: 500;
          font-size: 22px;
          color: #FFF;
          text-align: left;
          margin-bottom: 20px;
        }
       
        .joyride-button-text {
          font-size: 18px;
          line-height: 18px;
          padding: 10px;
          color: #fff;
          font-weight: 500;
        }
        .footer-actions {
          display: flex;
          justify-content: center;
        }
        .joyride-btn-start {
          background: #896896;
          border-radius: 34px;
          margin: 0 20px;
          padding: 3px 15px;
          min-width: 150px;
        }
        .joyride-btn-redirect {
          background: #896896;
          border-radius: 34px;
          margin: 0 35px;
          padding: 3px 15px;
          min-width: 300px;
        }
        .joyride-btn-next, .joyride-btn-back, .joyride-btn-restart {
          border: 2px solid #896896;
          border-radius: 34px;
          padding: 3px 15px;
          min-width: 100px;
        }
        .joyride-btn-next:hover, .joyride-btn-back:hover {
          background: #896896;
        }
        .storiez_details {
          color: #FFFFFF;
          text-align: left;
          font-size: 16px;
          padding-right: 20px;
          line-height: 24px;
        }
        .storiez_details__block {
          margin-bottom: 30px;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        li {
          font-weight: 500;
          margin-bottom: 15px;
        }

        .joyride-end .joyride-btn-start {
          width: 300px;
        }

        .icon-prev, .icon-next {
          display: none;
        }

        @media (max-width: 1000px), (max-height: 1000px) {
          .joyride-button-text {
            font-size: 16px;
            line-height: 16px;
          }
          .storiez_details {
            font-size: 14px;
          }

          .joyride-end .joyride-btn-start {
            width: 300px;
          }
        }

        @media (max-height: 860px){
          .__floater__open {
            top: calc(80px) !important;
            transform: translateX(-50%) !important;
           }
          .react-joyride__tooltip {
            max-height: calc(95vh - 80px);
          }
        }
        @media (max-width: 1300px) {
          .close-btn svg{
            width: 20px;
            height: 20px;
          }
          .joyride-button-text {
            font-size: 14px;
            line-height: 14px;
          }

          #react-joyride-step-1 .joyride-btn-redirect {
            min-width: 250px;
          }
          
          :global(#react-joyride-step-1 .main-container) {
            width: 300px !important;
          }
          .joyride-main {
            min-height: 400px;
          }
          .joyride-header .joyride-detail {
            font-size: 16px;
          }
          .storiez_details {
            font-size: 12px;
            line-height: 16px;
           }
          .storiez_details__block {
            margin-bottom: 20px;
          }
        }
        @media (max-width: 1200px) {
          .close-btn {
            top: 5px;
            right: 10px;
          }
          #react-joyride-step-1 .joyride-button-text {
            font-size: 12px;
            line-height: 12px;
            padding: 7px;
          } 
          .joyride-btn-next, .joyride-btn-back, .joyride-btn-restart {
            min-width: 70px;
          }
          #react-joyride-step-1 .joyride-btn-redirect {
            min-width: 200px;
          }
          .joyride-header .joyride-detail {
            font-size: 14px;
          } 
          .storiez_details {
            font-size: 10px;
            line-height: 14px;
          }
          .storiez_details__block {
            margin-bottom: 15px;
          }
          li {
            margin-bottom: 10px;
          }
        }
        @media (max-width: 1000px){
          #react-joyride-step-1 .react-joyride__tooltip {
            min-width: 80vw !important;
          }
          .joyride-main {
            min-height: 300px;
          }
          #react-joyride-step-1 .joyride-button-text {
            font-size: 10px;
            line-height: 10px;
          }
          #react-joyride-step-1  .joyride-btn-redirect {
            margin: 0 20px;
          }
        }
        @media (max-width: 800px){
          .joyride-main {
            min-height: 350px;
          }
        }

        @media (max-width: 600px) {
          #react-joyride-step-0 .joyride-header .joyride-detail {
            font-size: 25px;
          }
          .close-btn {
            right: 5px;
            top: 5px;
            font-size: 12px;
          }
          .joyride-header .joyride-detail {
            font-size: 16px;
          }
          .storiez_details {
            font-size: 12px;
            line-height: 16px;
            margin-top: 15px;
          }
          .react-joyride__tooltip {
            min-width: unset !important;
            width: 90vw !important;
          }
          #react-joyride-step-1 .react-joyride__tooltip {
            width: 90vw !important;
          }
          .row {
            display: block !important;
          }
          .joyride-col {
            max-width: 100%;
          }
          #react-joyride-step-1 .joyride-btn-redirect {
            min-width: 150px;
            padding: 1px 15px;
          }
          .joyride-btn-back, .joyride-btn-next{
            width: 28px;
            height: 28px;
            min-width: 28px;
            padding: 0;
          }
          .joyride-btn-back .joyride-button-text, .joyride-btn-next .joyride-button-text {
            display: none;
          }
          .icon-prev, .icon-next {
            display: flex;
            color: #FFF;
            margin: 0 auto;
          }
          .main-container {
            max-width: 80% !important;
          }
          :global(.step-style) {
            width: 20px !important;
            height: 20px !important;
          }
          :global(.step-count) {
            font-size: 12px !important;
          }
          :global(.step-container) {
            margin-top: 20px !important;
          }
          .joyride-main {
            min-height: 580px;
            margin-bottom: 0;
          }
          .joyride-main-1, .joyride-main-2{
            min-height: 603px;
            margin-bottom: 0;
          }
          li:last-child {
            margin-bottom: 0;
          }
          .joyride-button-text {
            padding: 5px;
          }
          .joyride-btn-restart {
            padding: 1px 10px;
          }
          .joyride-btn-start {
            min-width: 85px;
          }
          .joyride-actions {
            margin-bottom: 10px;
          }
          .joyride-end .joyride-btn-start {
            width: 220px;
          }
          .joyride-title {
            margin-top: 20px;
          }
          #react-joyride-step-1 .joyride-solid {
            margin: 0 15px !important;
          }
        }
        @media (max-width: 450px){
          .react-joyride__tooltip {
            max-height: calc(95vh - 80px - 80px);
          }
          .joyride-main {
            min-height: 530px;
            padding: 15px 0;
          }
        }
      `}</style>
    </>
  );
};

export default StoriezPopup;
