import React, { useContext, useEffect, useState } from 'react';
import { MdHome } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { lightOrDark } from '../../utils/color';
import { allBrands, INSPIFY_DARK_LOGO, INSPIFY_LIGHT_LOGO } from '../../config';
import {
  CONTACT_SUPPORT_EVENT,
  DID_CLICK_BACK_TO_SSP_HOME_BUTTON,
  DID_CLICK_CONTACT_SUPPORT_BUTTON,
  DID_CLICK_HOME_BUTTON
} from '../../utils/constants';
import {
  goToSspHome,
  isEmbeddedInStreamingStudio
} from '../../utils/streamingstudio';
import { HUB_USER_PERMISSION, IBrandSetup, IMainState, LoginStatus } from './../../interfaces/index';
import { hubNavToDefaultLanding } from './../utils/hubPagesRoute';
import { getAssetPath, getThemeColor, mobileBreakPoint } from './BrandStyle';
import { Alert } from './Common/Alert';
import Menu from './Menu';
import { BsQuestionCircle } from 'react-icons/bs';
import Nav from './Nav/Nav';
import { PageView } from '../../utils/pageView';
import { debounce } from 'lodash';
import { HubContext } from './HubContext';
import ALDesignerTour from './Tour/AssetLibraryTour/ALDesignerTour';
import { isUserOnMobileOnly } from '../../utils/deviceDetector';
import StoriezPopup from './Tour/StoriezTour/StoriezPopup';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import UserManagementTour from './Tour/UserManagement/UserManagementTour';
import TeamTour from './Tour/Team/TeamTour';
import BrandingSettingTour from './Tour/BrandingTour/BrandingSettingTour';
import { isInspifyBrand } from '../utils/hubEntitlements';

export const JIRA_HELP_CENTER_LINK = "https://inspify.atlassian.net/servicedesk/customer/portals";

interface Props {
  showNav?: boolean;
  theme?: 'dark' | 'light' | 'transparent';
  brandId: string;
  hideMenu?: boolean;
  brandSetup?: IBrandSetup;
}

const Header = ({
  showNav = true,
  theme: themeProp = 'dark',
  brandId,
  hideMenu,
  brandSetup
}: Props) => {
  const hubState = useSelector((state: IMainState) => state.clientState?.hub);
  const color = getThemeColor(brandId);
  const assetPath = getAssetPath(brandId);
  const {
    page,
    guidedTour,
    isGuidedTourReplay,
    setGuidedTour,
    setIsGuidedTourReplay,
    hasPermission,
    isShowingSessionPage
  } = useContext(HubContext) || {};
  const [show, setShow] = useState(false)
  const isMobile = isUserOnMobileOnly();
  const [showDesignerTour, setShowDesignerTour] = useState<number | null >();
  const isNotPreConfigured = allBrands.find(
    (brand) => brandId === brand.id
  )?.isNotPreConfigured;
  let theme = color.lightHeader ? 'light' : themeProp;

  if (brandSetup?.theme?.color?.primary) {
    theme = lightOrDark(brandSetup?.theme?.color?.primary);
  }

  const getBgImage = () => {
    if (theme === 'dark') {
      return (
        brandSetup?.theme?.logo?.light ||
        (isNotPreConfigured
          ? INSPIFY_LIGHT_LOGO
          : `${assetPath}/${isInspifyBrand(brandId) ? 'logo3' : 'logo'}-light.png?v3`)
      );
    }
    if (theme === 'light') {
      return (
        brandSetup?.theme?.logo?.dark ||
        (isNotPreConfigured ? INSPIFY_DARK_LOGO : `${assetPath}/${isInspifyBrand(brandId) ? 'logo3' : 'logo'}.png?v3`)
      );
    }
    return (
      brandSetup?.theme?.logo?.dark ||
      (isNotPreConfigured
        ? INSPIFY_LIGHT_LOGO
        : `${assetPath}/logo-light.png?v3`)
    );
  };

  const getBgColor = () => {
    if (theme === 'dark')
      return brandSetup?.theme?.color?.primary || color.dark;
    if (theme === 'light')
      return brandSetup?.theme?.color?.primary || color.light;
    return 'transparent';
  };

  const handleGoHome = debounce(() => {
    if (isEmbeddedInStreamingStudio()) {
      goToSspHome();
      logEvent(DID_CLICK_BACK_TO_SSP_HOME_BUTTON);
    } else {
      hubNavToDefaultLanding();
      logEvent(DID_CLICK_HOME_BUTTON);
    }
  }, 100);

  const listGuidedPage= [PageView.HUB_HOME, PageView.HUB_SESSION_OVERVIEW, PageView.HUB_USERMANAGEMENT, PageView.HUB_ASSET_LIBRARY, PageView.HUB_TEAMS, PageView.HUB_BRANDING]

  const closeGuidedTour = () => {
    setGuidedTour('');
    setShowDesignerTour(null)
  }
  const allowedToShowTourInMobile = () => {
    return isShowingSessionPage()
  }

  const showGuidedTour = () => {
    switch (guidedTour) {
      case PageView.HUB_HOME:
        return (
          <StoriezPopup
            onClose={() => {
              setGuidedTour('');
            }}
          />
        );
      case PageView.HUB_SESSION_OVERVIEW:
        return (
          <StoriezPopup
            onClose={() => {
              setGuidedTour('');
            }}
          />
        );
      case PageView.HUB_ASSET_LIBRARY: {
        return <ALDesignerTour onClose={() => setGuidedTour('')} />
      }
      case PageView.HUB_USERMANAGEMENT:
        return <UserManagementTour onClose={closeGuidedTour} showDesignerTour={showDesignerTour}/>
      case PageView.HUB_TEAMS:
        return <TeamTour onClose={closeGuidedTour} showDesignerTour={showDesignerTour}/>
        case PageView.HUB_BRANDING:
      return <BrandingSettingTour onClose={closeGuidedTour} showDesignerTour={showDesignerTour}/>
    }
  };

  useEffect(() => {
    if(isGuidedTourReplay) {
      setIsGuidedTourReplay(false)
    }
  }, [])

  useEffect(() => {
    if(isGuidedTourReplay) {
      setTimeout(() => setIsGuidedTourReplay(false), 6000)
    }
  }, [isGuidedTourReplay])
  return (
    <>
      <div className="header">
        {hubState?.loginStatus === LoginStatus.LOGGED_IN && !hideMenu && (
          <>
            <div className="left-container">
              <button
                className="home-nav btn"
                onClick={() => handleGoHome()}
                onTouchStart={() => handleGoHome()}
              >
                <MdHome />
              </button>
            </div>
            <div className="logo-container">
              <img className="logo" src={getBgImage()} />
            </div>
            <div className="d-flex">
              <button
                className="btn pl-0 pr-0"
                onClick={debounce(() => {
                  if (isGuidedTourReplay) setIsGuidedTourReplay(false)
                  if (
                    !isMobile &&
                    (listGuidedPage.includes(page) ||
                      (page === PageView.HUB_STORYBOOK && !hasPermission(HUB_USER_PERMISSION.STORYBOOK)))
                  ) {
                    setShow(!show);
                  } else if (allowedToShowTourInMobile()) {
                    setShow(!show);
                  } else {
                    window.open(JIRA_HELP_CENTER_LINK,'_blank');
                    logEvent(
                      CONTACT_SUPPORT_EVENT,
                      DID_CLICK_CONTACT_SUPPORT_BUTTON
                    );
                  }
                }, 100)}
              >
                <OverlayTrigger
                  placement="bottom-end"
                  show={isGuidedTourReplay}
                  delay={{ show: 1000, hide: 2000 }}
                  overlay={<Tooltip id="guided-tour-replay">You can replay this guide by clicking on the top right corner icon {" "}
                  <BsQuestionCircle
                  color={'#ffffff'}
                  fontSize={14}
                />
                  {" "} and selecting ‘Guided Tour’</Tooltip>}
                >
                  <BsQuestionCircle
                    color={theme === 'light' ? '#000000' : '#ffffff'}
                    fontSize={22}
                  />
                </OverlayTrigger>
              </button>
              <div className={`support-container ${show ? 'show' : ''}`}>
                <button
                  onClick={() => {
                    window.open(JIRA_HELP_CENTER_LINK,'_blank');
                    logEvent(
                      CONTACT_SUPPORT_EVENT,
                      DID_CLICK_CONTACT_SUPPORT_BUTTON
                    );
                  }}
                  className="support-btn btn"
                >
                  <span>Support</span>
                </button>
                <button
                  onClick={() => {
                    setShow(false);
                    setGuidedTour(isShowingSessionPage() ? PageView.HUB_SESSION_OVERVIEW: page);
                    setShowDesignerTour((new Date()).getTime());
                  }}
                  className="support-btn btn"
                >
                  <span>Guided Tour</span>
                </button>
              </div>
              {(!isMobile || allowedToShowTourInMobile()) && showDesignerTour && showGuidedTour()}
              {show && (
                <div className="backdrop" onClick={() => setShow(!show)}></div>
              )}
              <Menu theme={theme} storeId={hubState?.user?.storeId} />
            </div>
          </>
        )}
        {showNav ? <Nav /> : null}
        <style jsx>{`
          .header {
            height: 50px;
            background-color: ${getBgColor()};
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 70%;
            color: ${theme === 'dark' ? color.light : color.dark};
            z-index: 2;
            border-bottom: 1px solid ${color.grey};
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          :global(#guided-tour-replay) {
            top: 5px;
            transition: opacity 1s ease;
            animation: moveup 0.2s ease-in-out;
          }
          :global(#guided-tour-replay .tooltip-inner) {
            max-width: 270px;
            text-align: left;
            border-radius: 0.5rem;
            padding: 1rem 1rem;
          }
          :global(#guided-tour-replay .show) {
            opacity: 1;
          }
          .support-container {
            position: fixed;
            height: fit-content;
            right: 30px;
            display: none;
            left: auto;
            top: 49px;
            bottom: 0;
            text-align: left;
            width: 180px;
            z-index: 41;
            overflow-y: auto;
            color: #fff;
            transition: all 0.2s ease-in-out;
            color: #fff;
            background: rgba(0, 0, 0, 0.9);
            padding: 0 20px;
            border-radius: 3px;
          }
          .support-btn {
            display: block;
            color: #fff;
            text-align: left;
            padding: 15px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            width: 100%;
            border-radius: 0;
            display: flex;
            justify-content: space-between;
          }
          .support-container.show {
            display: block;
          }
          .backdrop {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }
          .logo-container {
            min-width: 40vw;
            max-width: 60vw;
            margin: auto;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .logo-container .logo {
            max-height: 85%;
            max-width: 100%;
          }
          .left-container {
            display: flex;
            align-items: center;
          }
          :global(.ssp) .header {
            height: 80px;
          }

          :global(.kbActive) .header {
            display: none;
          }
          .home-nav {
            color: ${theme === 'dark' ? color.light : color.dark};
            font-size: 22px;
          }
          :global(.touch) .home-nav {
            font-size: 30px;
            line-height: 40px;
          }

          :global(.ssp) .home-nav {
            font-size: 44px;
          }

          .menu-nav {
            left: auto;
            right: 0;
          }
          .header :global(.Nav) {
            display: none;
          }
          @media (min-width: ${mobileBreakPoint}px) {
            .header {
              height: 70px;
              background-size: auto 60%;
            }

            .home-nav,
            .menu-nav {
              margin: 10px 0;
            }
          }

          @media (orientation: landscape) and (max-height: 300px) {
            .header {
              display: none;
            }
          }
          @media (min-width: ${mobileBreakPoint}px) {
            .support-container {
              top: 69px;
            }
          }
          @keyframes moveup {
            from { 
              top : 10px; 
            }
            to { 
              top: 5px;
            }
          }
        `}</style>
      </div>
      <Alert />
    </>
  );
};

export default Header;
