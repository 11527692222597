import { useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { StoreHelpers } from 'react-joyride';
import ReactPlayer from 'react-player';
import { logClickButton } from '../../../../analytics';
import { getCurrentUserInfo, updateUserStoriezGuideTour } from '../../../../clientSideServices/users';
import { useTextContent } from '../../hooks/tour';
import { HubContext } from '../../HubContext';

const StepStart = ({ helpers }: { helpers?: StoreHelpers }) => {

  const { tc } = useTextContent();
  const { setIsGuidedTourReplay } = useContext(HubContext) || {};

  return (
    <div className="joyride-start">
      <div className="joyride-header">
        <div
          className="joyride-detail"
        >
          <span>{tc({ key: 'tour:introduction' })}</span>
        </div>
        <div
          className="close-btn"
          onClick={() => {
            helpers?.skip();
            logClickButton('StoriezGuid skip');
            getCurrentUserInfo().then((userInfo) => {
              if (userInfo?.attributes?.['custom:gt_storiez'] !=='skipped') {
                updateUserStoriezGuideTour("skipped")
              }
            });
            setIsGuidedTourReplay(true);
          }}
        >
          <AiOutlineClose size={23}/>
        </div>
      </div>
      <ReactPlayer
        playing={true}
        width="100%"
        height="100%"
        autoPlay
        playsinline
        muted
        loop
        url={
          'https://videos.inspify.com/assets/storiez/step0/step0.m3u8'
        }
      />
      <div className="joyride-footer">
        <hr className="joyride-solid" />
        <div className="joyride-detail">
          {tc({ key: 'tour:storiez_let_started' })}
        </div>
        <div className="joyride-detail">
          {tc({ key: 'tour:storiez_what_can_do' })}
        </div>
        <div className="joyride-detail">
          {tc({ key: 'tour:storiez_click_start' })}
        </div>
        <button
          className="btn joyride-btn-start my-3"
          onClick={() => {
            helpers?.next();
            logClickButton('StoriezGuid start');
          }}
        >
          <div className="joyride-button-text">{tc({ key: 'tour:start' })}</div>
        </button>
      </div>
    </div>
  );
};

export default StepStart;