import { mobileBreakPoint } from '../BrandStyle';
import { useContext } from 'react';
import { HubContext } from './../HubContext';
import { VirtualAppointmentNav, NudgeNav } from './NavIcons';

import { hubNavTo } from '../../utils/hubPagesRoute';
import { PageView } from '../../../utils/pageView';

interface Props {
  showLabel?: boolean;
}

const Nav = ({ showLabel = false }: Props) => {
  const { brandId } = useContext(HubContext);

  return (
    <div className="Nav">
      <button onClick={() => hubNavTo(PageView.HUB_NUDGES_CREATE)}>
        <VirtualAppointmentNav
          showLabel={showLabel}
          counter={10}
          onClick={() => null}
        />
      </button>

      <button onClick={() => hubNavTo(PageView.HUB_NUDGES)}>
        <NudgeNav showLabel={showLabel} counter={10} onClick={() => null} />
      </button>
      <button onClick={() => hubNavTo(PageView.HUB_NUDGES_CREATE)}>
        <NudgeNav showLabel={showLabel} counter={10} onClick={() => null} />
      </button>

      <a href={`/hub/${brandId}#/nudges/new`}>
        <NudgeNav showLabel={showLabel} counter={10} onClick={() => null} />
      </a>

      <style jsx>{`
        .Nav {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .Nav :global(svg) {
          -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
          width: 100%;
          height: auto;
        }

        .Nav :global(.nav-icon) {
          width: 100px;
          padding-right: 10px;
          margin: auto;
          cursor: pointer;
        }
        .Nav a :global(.label) {
          display: block;
          text-transform: uppercase;
          font-weight: normal;
          text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          font-size: 0.6em;
          color: #fff;
        }
        @media (min-width: ${mobileBreakPoint}px) {
          .Nav a :global(.label) {
            font-size: 1em;
          }
        }
      `}</style>
    </div>
  );
};

export default Nav;
