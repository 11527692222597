import { useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { StoreHelpers } from 'react-joyride';
import { logClickButton } from '../../../../analytics';
import { HUB_USER_PERMISSION } from '../../../../interfaces';
import { PageView } from '../../../../utils/pageView';
import { shouldShowStorybooks } from '../../../utils/hubEntitlements';
import { hubNavTo } from '../../../utils/hubPagesRoute';
import { useTextContent } from '../../hooks/tour';
import { HubContext } from '../../HubContext';

const StepEnd = ({ helpers }: { helpers?: StoreHelpers }) => {
  
  const { storeId, brandId, meetUser, user, checkUserPermissions, chooseCheckPermissionResult } = useContext(HubContext) || {};

  const hasALPermission = checkUserPermissions(
    HUB_USER_PERMISSION.ASSET_LIBRARY
  );

  const hasSBPermission = chooseCheckPermissionResult(
    shouldShowStorybooks({
      storeId,
      brandId,
      permissions: meetUser?.permissions,
      userType: user?.userType
    }),
    checkUserPermissions(HUB_USER_PERMISSION.STORYBOOK)
  );

  const { tc } = useTextContent();
  const { setIsGuidedTourReplay } = useContext(HubContext) || {};
  const onRestart = () => {
    helpers?.reset(true)
    logClickButton('StoriezGuid restart');
  };
  return (
    <div className="joyride-end px-4 py-4">
      <div className="joyride-header d-flex">
        <button className="btn joyride-btn-restart py-2" onClick={onRestart}>
          <div className="joyride-button-text">
            {tc({ key: 'tour:restart' })}
          </div>
        </button>
        <div className="close-btn" onClick={() => {
          helpers?.skip()
          setIsGuidedTourReplay(true);
          logClickButton('StoriezGuid skip');
        }}>
          <AiOutlineClose size={23} />
        </div>
      </div>
      <div className="joyride-title text-center">
        <strong style={{ fontSize: '35px' }} className="joyride-detail">
          {tc({ key: 'tour:amazing' })}
        </strong>
      </div>
      <hr className="joyride-solid" />
      <div className="joyride-detail my-3 mx-auto" style={{fontSize: '20px', maxWidth: '400px'}}>
        {tc({ key: 'tour:ready_create' })}
      </div>
      <br />
      <div className='joyride-actions'>
      <button disabled={!hasALPermission} className="btn joyride-btn-start py-2 my-2" onClick={() => {
          helpers.skip();
          hubNavTo(PageView.HUB_ASSET_LIBRARY);
        }}>
        <div className="joyride-button-text">
          {tc({ key: 'tour:upload_your_first_assets' })}
        </div>
      </button>
      <button disabled={!hasSBPermission} className="btn joyride-btn-start py-2 my-2" onClick={() => {
          helpers.skip();
          hubNavTo(PageView.HUB_STORYBOOK);
        }}>
        <div className="joyride-button-text">
          {tc({ key: 'tour:storiez_create_a_storybook' })}
        </div>
      </button>
      <button className="btn joyride-btn-start py-2 my-2" onClick={() => {
          helpers.skip();
          setIsGuidedTourReplay(true)
          hubNavTo(PageView.HUB_SESSION_OVERVIEW);
        }}>
        <div className="joyride-button-text">
          {tc({ key: 'tour:storiez_start_meeting' })}
        </div>
      </button>
      </div>
    </div>
  );
};

export default StepEnd;
