import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HubAlertType, IMainState } from '../../../interfaces/index';
import { mobileBreakPoint } from './../BrandStyle/index';
import { actionHubCloseAlert } from './../../../redux/actions';
import { logEvent } from './../../../analytics/index';
import { DID_SHOWING_ALERT } from '../../../utils/constants';

export const Alert = () => {
  const [render, setRender] = React.useState(false);
  const dispatch = useDispatch();
  const alert = useSelector(
    (state: IMainState) => state.clientState.hub?.alert
  );
  const open = alert?.open || false;
  const text = alert?.text || '';
  const type = alert?.type || HubAlertType.ERROR;

  const getClassType = () => {
    if (type === HubAlertType.ERROR) return 'danger';
    if (type === HubAlertType.SUCCESS) return 'success';
    return 'info';
  };

  React.useEffect(() => {
    if (open) {
      setRender(true);
      setTimeout(() => dispatch(actionHubCloseAlert()), 3000);
      logEvent(DID_SHOWING_ALERT, type, { text });
    }
  }, [open]);

  if (!render) return null;
  return (
    <div
      className={`alert alert-${getClassType()} ${open ? 'show' : 'hide'}`}
      role="alert"
    >
      {text}
      <style jsx>{`
        .alert {
          position: fixed;
          z-index: 9999;
          padding: 0 10px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8em;
          line-height: 1.2;
          top: 0;
          left: 0;
          right: 0;
          border-radius: 0;
          border-width: 0 0 1px;
          text-align: center;
          transform: translateY(-100px);
        }
        .show {
          -webkit-animation: slide-in-top 0.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }

        .hide {
          -webkit-animation: slide-out-top 0.5s
            cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
          animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
            both;
        }

        @-webkit-keyframes slide-out-top {
          0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
            opacity: 0;
          }
        }
        @keyframes slide-out-top {
          0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
            opacity: 0;
          }
        }

        @-webkit-keyframes slide-in-top {
          0% {
            -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
          }
        }
        @keyframes slide-in-top {
          0% {
            -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
          }
        }

        @media (min-width: ${mobileBreakPoint}px) {
          .alert {
            height: 70px;
            font-size: 1em;
          }
        }
      `}</style>
    </div>
  );
};

export default Alert;
