import React from 'react';
import ReactJoyride, {
  CallBackProps,
  STATUS,
  StoreHelpers
} from 'react-joyride';
import { IHubTourState } from '..';
import { getCurrentUserInfo, updateTeamGuideTour } from '../../../../clientSideServices/users';
import {
  StepStart,
  StepEnd,
  StepNewTeam,
  StepTeamName,
  StepInviteMembers,
  StepAddTeamMember,
  StepRemoveTeamMember,
  StepSave
} from './TeamStep';

const TeamTour = ({onClose, showDesignerTour}: {onClose?: () => void, showDesignerTour?: number | null}) => {
  const [step, setStep] = React.useState({ no: 0, size: 6, mSize: 6 });
  const [helpers, setHelpers] = React.useState<StoreHelpers>();
  const [state, setState] = React.useState<IHubTourState>({
    run: true,
    steps: [
      {
        content: <StepStart helpers={helpers} />,
        placement: 'center',
        target: 'body'
      }
    ]
  });

  React.useEffect(() => {
    if(helpers?.info()?.action === 'close') helpers?.reset(true)
  }, [showDesignerTour])

  React.useEffect(() => {
    if (!state.run && onClose) {
      onClose()
    }
  }, [state.run])

  React.useEffect(() => {
    let fetching;
    if (step.no > 0) {
      getCurrentUserInfo().then((userInfo) => {
        if (userInfo?.attributes?.['custom:gt_teams'] !=='skipped') {
          updateTeamGuideTour("skipped")
        }
      });
    }
    if (helpers && step.no === 1) {
      fetching = setInterval(() => {
        const elements = document.querySelectorAll('.create-team .create-btn');
        if (elements?.length > 0) {
          helpers.next();
          const steps = state.steps;
          setState({ ...state, steps: steps });
          clearInterval(fetching);
          setTimeout(() => helpers.open(), 100);
        }
      }, 1000);
    }
    if (helpers && step.no === 6) {
      fetching = setInterval(() => {
        const elements = document.querySelectorAll('.create-team .create-btn');
        if (elements?.length === 0) {
          helpers.next();
          const steps = state.steps;
          setState({ ...state, steps: steps });
          clearInterval(fetching);
          setTimeout(() => helpers.open(), 100);
        }
      }, 1000);
    }
    return () => {
      fetching && clearInterval(fetching);
    };
  }, [helpers, step]);
  const getHelpers = (helpers: StoreHelpers) => {
    setHelpers(helpers);

    setState({
      ...state,
      steps: [
        {
          content: <StepStart helpers={helpers} />,
          placement: 'center',
          target: 'body'
        },
        {
          content: (
            <StepNewTeam helpers={helpers} stepNo={`1 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'left-start',
          spotlightPadding: 20,
          target: '.create-btn .create-team'
        },
        {
          content: (
            <StepTeamName helpers={helpers} stepNo={`2 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right-start',
          spotlightPadding: 5,
          target: '.create-team > div:nth-child(1n)'
        },
        {
          content: (
            <StepInviteMembers helpers={helpers} stepNo={`3 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right-start',
          spotlightPadding: 5,
          target: '.search-box .team-search'
        },
        {
          content: (
            <StepAddTeamMember helpers={helpers} stepNo={`4 / ${step.size}`} />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right-start',
          spotlightPadding: 4,
          target: '.team-user-list > div:nth-child(1n)'
        },
        {
          content: (
            <StepRemoveTeamMember
              helpers={helpers}
              stepNo={`5 / ${step.size}`}
            />
          ),
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right',
          spotlightPadding: 10,
          target: '#sel-member-0'
        },
        {
          content: <StepSave helpers={helpers} stepNo={`6 / ${step.size}`} />,
          floaterProps: {
            disableAnimation: true
          },
          placement: 'right-start',
          spotlightPadding: 3,
          target: '.create-btn .btn-save-btn'
        },
        {
          content: <StepEnd helpers={helpers} />,
          placement: 'center',
          target: 'body'
        }
      ]
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    setStep({ ...step, no: index });
    if (finishedStatuses.includes(status)) {
      setState({ ...state, run: false });
    }
  };
  const { run, steps } = state;
  return (
    <>
      <ReactJoyride
        callback={handleJoyrideCallback}
        disableScrolling={true}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        disableOverlayClose={true}
        styles={{
          beacon: {
            display: 'none'
          },
          buttonClose: {
            display: 'none'
          },
          buttonNext: {
            display: 'none'
          },
          buttonBack: {
            display: 'none'
          },
          options: {
            backgroundColor: '#000000',
            width: 450,
            zIndex: 10000,
            arrowColor: '#000000'
          }
        }}
      />
      <style jsx global>{`
        .joyride-title {
          margin-top: 30px;
        }
        .joyride-solid {
          border-top: 1px solid #896896;
        }
        .joyride-detail {
          font-size: 0.95rem;
          color: #ffffff;
        }
        .joyride-detail-title {
          font-size: 1.1rem;
          font-weight: 500;
          color: #ffffff;
        }
        .joyride-button-text {
          font-size: 1.1rem;
          color: #fff;
          padding: 0 12px;
          font-weight: 500;
        }
        .joyride-btn-start {
          background: #896896;
          border-radius: 20px;
          margin-top: 20px;
        }
        .joyride-container {
          font-size: 0.95rem;
          text-align: left;
          margin-bottom: 10px;
          color: #ffffff;
        }
        .joyride-btn-next {
          background: #896896;
          border-radius: 20px;
          float: right;
          padding: 5px 10px;
          margin-top: 3px;
          color: #896896;
        }
        .joyride-btn-skip {
          background: #c4c4c400;
          border-radius: 20px;
          float: right;
          color: #ffffff;
        }
      `}</style>
    </>
  );
};

export default TeamTour;
