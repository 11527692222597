import Counter from './Counter';
import { IconVirtualAppointment, IconVirtualWalkIn, IconNudge } from './Icons';

interface Props {
  onClick: () => void;
  counter: number;
  showLabel: boolean;
}

export const VirtualAppointmentNav = ({
  showLabel,
  counter,
  onClick
}: Props) => {
  return (
    <div className="nav-icon with-shadow" onClick={onClick}>
      {IconVirtualAppointment}
      {showLabel && <span className="label">Virtual Appointment</span>}
      <Counter count={counter} />
      <style jsx>{`
        div {
          position: relative;
          display: inline-block;
        }
      `}</style>
    </div>
  );
};

export const VirtualWalkInNav = ({ showLabel, counter, onClick }: Props) => {
  return (
    <div className="nav-icon with-shadow" onClick={onClick}>
      {IconVirtualWalkIn}
      {showLabel && <span className="label">Virtual Walk In</span>}
      <Counter count={counter} />
      <style jsx>{`
        div {
          position: relative;
          display: inline-block;
        }
      `}</style>
    </div>
  );
};

export const NudgeNav = ({ showLabel, counter, onClick }: Props) => {
  return (
    <div className="nav-icon with-shadow" onClick={onClick}>
      {IconNudge}
      {showLabel && <span className="label">Virtual Invite</span>}
      <Counter count={counter} />
      <style jsx>{`
        div {
          position: relative;
          display: inline-block;
        }
      `}</style>
    </div>
  );
};
