import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useTranslation } from '../../../i18n';

export interface TourHookValue {
  tc: ({ key, keyPrefix }: { key: string; keyPrefix?: Object }) => string;
}

const cachedTour = {
  data: {}
}

export const useTextContent = (): TourHookValue => {
  const { i18n } = useTranslation();
  const { data } = useQuery(
    'all-tour',
    () =>{
      if (!isEmpty(cachedTour.data)) {
        return Promise.resolve({tour: cachedTour.data})
      }
      return fetch('/asset/tour.json')
      .then((response) => response.json())
      .then((data) => {
        cachedTour.data= data
        return { tour: data };
      })
    },
    { staleTime: Infinity }
  );
  const tc = ({
    key,
    keyPrefix
  }: {
    key: string;
    keyPrefix?: Object;
  }): string => {
    const langCode = i18n.language;
    const splitText = key.split(':');
    const langGroup = splitText[0];
    const textKey = splitText[1];
    if (!splitText[1]) return key;
    const text =
      data?.[langGroup]?.[langCode]?.[textKey] ||
      data?.[langGroup]?.['en']?.[textKey] ||
      key;
    try {
      Object.keys(keyPrefix || {})?.forEach((key) => {
        text.replace(`{{${key}}}`, keyPrefix[key]);
      });
    } catch {
      return text;
    }
    return text;
  };

  return {
    tc
  };
};
