import { BsQuestionCircle } from 'react-icons/bs';
import { StoreHelpers } from 'react-joyride';
import { logClickButton } from '../../../../analytics';
import { useTextContent } from '../../hooks/tour';

const StepProgress = ({
  current,
  total
}: {
  current: number;
  total: number;
}) => (
  <div className="joyride-step-progress">
    <span>{current}/{total}</span>
  </div>
);

const ButtonJoyride = ({
  helpers,
  btnType,
  nextLabel,
  skipLabel,
  onSkip,
  onNext,
}: {
  helpers: StoreHelpers;
  btnType?: string;
  nextLabel: string;
  skipLabel: string;
  onSkip?: () => void;
  onNext?: () => void;
}) => (
  <div className="joyride-footer-actions">
    <button
      className="btn joyride-btn-skip"
      onClick={() => {
        helpers?.skip();
        onSkip?.()
        logClickButton('AssetLibGuid skip');
      }}
    >
      <span className="joyride-button-text">{skipLabel}</span>
    </button>
    <button
      className="btn joyride-btn-next"
      onClick={() => {
        if (btnType === 'close') {
          helpers?.prev();
          helpers?.close();
          logClickButton('AssetLibGuid close');
        } else {
          helpers?.next();
          onNext?.()
          logClickButton('AssetLibGuid next');
        }
      }}
    >
      <span className="joyride-button-text">{nextLabel}</span>
    </button>
  </div>
);
export const StepStart = ({ helpers }: { helpers?: StoreHelpers }) => {
  const { tc } = useTextContent();
  return (
    <div className='joyride-start'>
      <div className="joyride-title">
        <div style={{ fontSize: '30px', fontWeight: 600, textDecoration: "uppercase" }} className="joyride-detail">
          {tc({ key: 'tour:asset_library' })}
        </div>
        <div style={{ fontSize: '24px' }} className="joyride-detail">
          {tc({ key: 'tour:guided_tour' })}
        </div>
        <br />
        <div className="joyride-detail" style={{width: '350px', margin: 'auto'}}>
          {tc({ key: 'tour:asset_start' })}
        </div>
      </div>
      <hr className="joyride-solid" />
      <div className="joyride-detail">{tc({ key: 'tour:asset_set_up' })}</div>
      <div className="joyride-detail">{tc({ key: 'tour:asset_ready' })}</div>
      <br />
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <div className="joyride-button-text">
          {tc({ key: 'tour:start_tour' })}
        </div>
      </button>
    </div>
  );
};
export const StepCategoryOverview = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">
          {tc({ key: 'tour:category_overview' })}
        </strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:category_overview_detail' })}
      </div>
      <br />
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepSubCategory = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <span className="font">{tc({ key: 'tour:sub_category' })}</span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:sub_category_detail' })}
      </div>
      <br />
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepCreateCategoryFirstStep = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:create_category' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:create_category_first_step' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepCreateCategorySecondStep = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:create_category' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:create_category_second_step_1' })}
        <br />
        <br />
        {tc({ key: 'tour:create_category_second_step_2' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' }),
        onSkip: () => {
          const element = document.querySelector('.form__edit-category button') as HTMLElement | null;
          if (element) {
            element.click()
          }
        }
      })}
    </>
  );
};

export const StepUploadFilesStep1 = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:asset_upload' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:asset_upload_first_way_1' })}
        <br />
        {tc({ key: 'tour:asset_upload_first_way_2' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};

export const StepUploadFilesStep2 = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:asset_upload' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:asset_upload_second_way' })}
      </div>
      <br />
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};

export const StepUploadFilesNotes = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:asset_upload' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:asset_upload_note' })}
        <br />
        <br />
        <div className='joyride-sub-note'>
        {tc({ key: 'tour:asset_upload_sub_note_label' })}
        <br />
        <ul>
          <li>{tc({ key: 'tour:asset_upload_sub_note_details' })}</li>
        </ul>
        </div>
      </div>
      <br />
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' }),
        onSkip: () => {
          const element = document.querySelector('.asset-upload-popup .popup-footer button') as HTMLElement | null;
          if (element) {
            element.click()
          }
        }
      })}
    </>
  );
};

export const StepSelectionModes = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:selection_mode' })}</strong>
      </div>
      <div className="joyride-container">
        <strong>{tc({ key: 'tour:range_select' })}</strong>
        <br />
        {tc({ key: 'tour:range_select_detail' })}
        <br />
        <br />
        <strong>{tc({ key: 'tour:multiple_select' })}</strong>
        <br />
        {tc({ key: 'tour:multiple_select_detail' })}
        <br />
        <br />
        <strong>{tc({ key: 'tour:unselect' })}</strong>
        <br />
        {tc({ key: 'tour:unselect_detail' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};

export const StepAssetDetailsStep1 = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:asset_detail_label' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:asset_detail_text1' })}
        <br />
        <br />
        {tc({ key: 'tour:asset_detail_text2' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' }),
        onSkip: () => {
          const element = document.querySelector('.list-assets') as HTMLElement | null;
          if (element) {
            element.click()
          }
        },
        onNext: () => {
          const element = document.querySelector('.list-assets') as HTMLElement | null;
          if (element) {
            element.click()
          }
        }
      })}
    </>
  );
};

export const StepAssetDetailsStep2 = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:asset_detail_label' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:asset_detail_text3' })}
        <br />
        <br />
        {tc({ key: 'tour:asset_detail_text4' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};

export const StepSearchArea = ({ helpers, current, total }: { helpers: StoreHelpers, current: number, total: number }) => {
    const { tc } = useTextContent();
  return (
    <>
      {StepProgress({current, total})}
      <div className="joyride-title">
        <strong className="font">{tc({ key: 'tour:search_area' })}</strong>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:search_area_detail1' })}
        <br />
        <br />
        {tc({ key: 'tour:search_area_detail2' })}
      </div>
      <br />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};

export const StepEnd = ({ helpers }: { helpers: StoreHelpers }) => {
    const { tc } = useTextContent();
  return (
    <div className='joyride-end'>
      <div className="joyride-title">
        <strong style={{fontSize: '30px'}} className="joyride-detail">
          {tc({ key: 'tour:asset_lib_great_work' })}
        </strong>
        <br />
        <strong className="joyride-detail">
          {tc({ key: 'tour:asset_lib_great_work_ready' })}
        </strong>
      </div>
      <hr className="joyride-solid" />
      <div className="joyride-detail">
        {tc({ key: 'tour:asset_lib_great_work_replace1' })}
        <BsQuestionCircle
                  color={'#ffffff'}
                  fontSize={22}
                />
        {tc({ key: 'tour:asset_lib_great_work_replace2' })}
        
      </div>
      <br/>
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <div className="joyride-button-text">
          {tc({ key: 'tour:get_started' })}
        </div>
      </button>
    </div>
  );
};
