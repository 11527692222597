import React from 'react';
import ReactJoyride, {
  CallBackProps,
  STATUS,
  StoreHelpers
} from 'react-joyride';
import { IHubTourState } from '..';
import { getCurrentUserInfo, updateBrandingSettingGuideTour } from '../../../../clientSideServices/users';
import { isMobileViewport } from '../../../../utils/deviceDetector';
import {
  StepBrandCustomStyle,
  StepBrandInfo,
  StepBrandLogo,
  StepBrandPreviewStyle,
  StepBrandTheme,
  StepColorFontMobile,
  StepEnd,
  StepIntroOutroVideoMobile,
  StepIntroVideo,
  StepMeetingBgLandscape,
  StepMeetingBgPortrait,
  StepMeetMobile,
  StepMenuItemAssets,
  StepMenuItemBrandMobile,
  StepMenuItemProfile,
  StepMenuItemTheme,
  StepOgImage,
  StepOutroVideo,
  StepStart
} from './BrandingSettingStep';

const BrandingSettingTour = ({
  onClose,
  showDesignerTour
}: {
  onClose?: () => void;
  showDesignerTour?: number | null;
}) => {
  const isMobileView = isMobileViewport();
  const [step, setStep] = React.useState({ no: 0, size: 12, mSize: 8 });
  const [helpers, setHelpers] = React.useState<StoreHelpers>();
  const [state, setState] = React.useState<IHubTourState>({
    run: true,
    steps: [
      {
        content: <StepStart helpers={helpers} />,
        placement: 'center',
        target: 'body'
      }
    ]
  });

  React.useEffect(() => {
    if(helpers?.info()?.action === 'close') helpers?.reset(true)
  }, [showDesignerTour])

  React.useEffect(() => {
    if (!state.run && onClose) {
      onClose()
    }
  }, [state.run])


  const action = helpers?.info().action;
  React.useEffect(() => {
    const contentContainer = document.querySelector(
      '#hub-layout .content-wrapper'
    ) as HTMLElement | null;
    if (contentContainer) {
      if (!state.run || action === 'close') {
        contentContainer.style?.removeProperty('overflow');
      }
      if (state.run && action === 'next') {
        contentContainer.style?.setProperty('overflow', 'initial');
      }
    }
  }, [action, state.run]);

  React.useEffect(() => {
    let fetching;
    if (step.no > 0) {
      getCurrentUserInfo().then((userInfo) => {
        if (userInfo?.attributes?.['custom:gt_branding'] !=='skipped') {
          updateBrandingSettingGuideTour("skipped")
        }
      });
    }
    if (
      helpers &&
      ((step.no === 2 && !isMobileView) || (step.no === 3 && isMobileView))
    ) {
      fetching = setInterval(() => {
        const elements = document.querySelectorAll(
          '.brand-form .brand-custom-style'
        );
        if (elements?.length > 0) {
          helpers.next();
          const steps = state.steps;
          setState({ ...state, steps: steps });
          clearInterval(fetching);
          setTimeout(() => helpers.open(), 100);
        }
      }, 1000);
    }
    if (
      helpers &&
      ((step.no === 7 && !isMobileView) || (step.no === 5 && isMobileView))
    ) {
      fetching = setInterval(() => {
        const element = document.getElementById('og-image');
        if (element) {
          helpers.next();
          const steps = state.steps;
          setState({ ...state, steps: steps });
          clearInterval(fetching);
          setTimeout(() => helpers.open(), 100);
        }
      }, 1000);
    }
    return () => {
      fetching && clearInterval(fetching);
    };
  }, [helpers, step]);
  const getHelpers = (helpers: StoreHelpers) => {
    setHelpers(helpers);
    if (isMobileView) {
      setState({
        ...state,
        steps: [
          {
            content: <StepStart helpers={helpers} />,
            placement: 'center',
            target: 'body'
          },
          {
            content: (
              <StepMenuItemProfile
                helpers={helpers}
                stepNo={`1 / ${step.mSize}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'bottom',
            spotlightPadding: 2,
            target: '.brand-form .container-fluid'
          },
          {
            content: (
              <StepMenuItemProfile
                helpers={helpers}
                stepNo={`2 / ${step.mSize}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'bottom',
            spotlightPadding: 2,
            target: '.mobile-navigator .address'
          },
          {
            content: (
              <StepMenuItemBrandMobile
                helpers={helpers}
                stepNo={`3 / ${step.mSize}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'bottom',
            spotlightPadding: 2,
            target: '.mobile-menu .menu > div:nth-child(2n)'
          },
          {
            content: (
              <StepBrandLogo helpers={helpers} stepNo={`4 / ${step.mSize}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '.brand-custom-style > div:nth-child(1n)'
          },
          {
            content: (
              <StepColorFontMobile
                helpers={helpers}
                stepNo={`5 / ${step.mSize}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'bottom',
            spotlightPadding: 2,
            target: '.mobile-navigator .style'
          },
          {
            content: (
              <StepOgImage helpers={helpers} stepNo={`6 / ${step.mSize}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '#og-image'
          },
          {
            content: (
              <StepIntroOutroVideoMobile
                helpers={helpers}
                stepNo={`7 / ${step.mSize}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '.mobile-navigator .address'
          },
          {
            content: (
              <StepMeetMobile helpers={helpers} stepNo={`8 / ${step.mSize}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '.mobile-navigator .logo'
          },
          {
            content: <StepEnd helpers={helpers} />,
            placement: 'center',
            target: 'body'
          }
        ]
      });
    } else {
      setState({
        ...state,
        steps: [
          {
            content: <StepStart helpers={helpers} />,
            placement: 'center',
            target: 'body'
          },
          {
            content: (
              <StepBrandInfo helpers={helpers} stepNo={`1 / ${step.size}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 10,
            target: '.brand-info'
          },
          {
            content: (
              <StepMenuItemTheme
                helpers={helpers}
                stepNo={`2 / ${step.size}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 2,
            target: '.menu-item:nth-child(2n) '
          },
          {
            content: (
              <StepBrandLogo helpers={helpers} stepNo={`3 / ${step.size}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '.brand-custom-style > div:nth-child(1n)'
          },
          {
            content: (
              <StepBrandTheme helpers={helpers} stepNo={`4 / ${step.size}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 2,
            target: '.brand-custom-style > div:nth-child(2n)'
          },
          {
            content: (
              <StepBrandCustomStyle
                helpers={helpers}
                stepNo={`5 / ${step.size}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 20,
            target: '.brand-custom-style > div:nth-child(3n)'
          },
          {
            content: (
              <StepBrandPreviewStyle
                helpers={helpers}
                stepNo={`6 / ${step.size}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'left',
            spotlightPadding: 1,
            target: '.brand-custom-style-preview > div:nth-child(1n)'
          },
          {
            content: (
              <StepMenuItemAssets
                helpers={helpers}
                stepNo={`7 / ${step.size}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 2,
            target: '.menu-item:nth-child(3n)'
          },
          {
            content: (
              <StepOgImage helpers={helpers} stepNo={`8 / ${step.size}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '#og-image'
          },
          {
            content: (
              <StepMeetingBgLandscape
                helpers={helpers}
                stepNo={`9 / ${step.size}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '#meetingBgLandscape'
          },
          {
            content: (
              <StepMeetingBgPortrait
                helpers={helpers}
                stepNo={`10 / ${step.size}`}
              />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'left',
            spotlightPadding: 4,
            target: '#meetingBgPortrait'
          },
          {
            content: (
              <StepIntroVideo helpers={helpers} stepNo={`11 / ${step.size}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '#intro-video'
          },
          {
            content: (
              <StepOutroVideo helpers={helpers} stepNo={`12 / ${step.size}`} />
            ),
            floaterProps: {
              disableAnimation: true
            },
            placement: 'right',
            spotlightPadding: 4,
            target: '#outro-video'
          },
          {
            content: <StepEnd helpers={helpers} />,
            placement: 'center',
            target: 'body'
          }
        ]
      });
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    setStep({ ...step, no: index });
    if (finishedStatuses.includes(status)) {
      setState({ ...state, run: false });
    }
  };
  const { run, steps } = state;
  return (
    <>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        disableOverlayClose={true}
        styles={{
          beacon: {
            display: 'none'
          },
          buttonClose: {
            display: 'none'
          },
          buttonNext: {
            display: 'none'
          },
          buttonBack: {
            display: 'none'
          },
          options: {
            backgroundColor: '#000000',
            width: step.no == 0 || step.no == 13 ? 450 : 400,
            zIndex: 10000,
            arrowColor: '#000000'
          }
        }}
      />
      <style jsx global>{`
        .joyride-title {
          margin-top: 20px;
        }
        .joyride-solid {
          border-top: 1px solid #896896;
        }
        .joyride-detail {
          font-size: 0.95rem;
          color: #ffffff;
        }
        .joyride-detail-title {
          font-size: 1.1rem;
          color: #ffffff;
        }
        .joyride-button-text {
          font-size: 1.1rem;
          color: #fff;
          padding: 0 12px;
          font-weight: 500;
        }
        .joyride-btn-start {
          background: #896896;
          border-radius: 20px;
          margin-top: 20px;
        }
        .joyride-container {
          font-size: 0.95rem;
          text-align: left;
          margin-bottom: 10px;
          color: #ffffff;
        }
        .joyride-btn-next {
          background: #896896;
          border-radius: 20px;
          float: right;
          padding: 5px 10px;
          margin-top: 3px;
          color: #896896;
        }
        .joyride-btn-skip {
          background: #c4c4c400;
          border-radius: 20px;
          float: right;
          color: #ffffff;
        }
      `}</style>
    </>
  );
};

export default BrandingSettingTour;
