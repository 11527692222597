import { BsQuestionCircle } from 'react-icons/bs';
import { StoreHelpers } from 'react-joyride';
import { logClickButton } from '../../../../analytics';
import { useTextContent } from '../../hooks/tour';

const ButtonJoyride = ({
  helpers,
  btnType,
  nextLabel = 'next',
  skipLabel = 'Skip Tour'
}: {
  helpers: StoreHelpers;
  btnType?: string;
  nextLabel?: string;
  skipLabel?: string;
}) => (
  <div
    className="joyride-container"
    style={{ paddingBottom: '5px', marginTop: '50px' }}
  >
    <button
      className="btn joyride-btn-next"
      onClick={() => {
        if (btnType === 'close') {
          helpers?.prev();
          helpers?.close();
          logClickButton('TeamGuid close');
        } else {
          helpers?.next();
          logClickButton('TeamGuid next');
        }
      }}
    >
      <span className="joyride-button-text">{nextLabel}</span>
    </button>
    <button
      className="btn joyride-btn-skip"
      onClick={() => {
        helpers?.skip();
        logClickButton('TeamGuid skip');
      }}
    >
      <span className="joyride-detail">{skipLabel}</span>
    </button>
  </div>
);
export const StepStart = ({ helpers }: { helpers: StoreHelpers }) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-title">
        <p style={{ marginBottom: '1.4rem' }}>
          <strong style={{ fontSize: '24px' }} className="joyride-detail">
            {tc({ key: 'tour:teams' })}
          </strong>
        </p>
        <span
          style={{ fontSize: '1.1rem', fontWeight: 500 }}
          className="joyride-detail-title"
        >
          {tc({ key: 'tour:teams_detail' })}
        </span>
      </div>
      <hr className="joyride-solid" />
      <div style={{ margin: '0 60px' }} className="joyride-detail">
        {tc({ key: 'tour:are_you_ready' })}
      </div>
      <div className="joyride-title" />
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <span className="joyride-button-text">
          {tc({ key: 'tour:start_tour' })}
        </span>
      </button>
    </>
  );
};
export const StepNewTeam = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="font joyride-detail-title">
          {tc({ key: 'tour:new_team' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:new_team_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepTeamName = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="font joyride-detail-title">{tc({ key: 'tour:team_name' })}</span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:team_name_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepInviteMembers = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="font joyride-detail-title">{tc({ key: 'tour:invite_members' })}</span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:invite_members_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepAddTeamMember = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:add_a_team_member' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:add_a_team_member_detail' })}
      </div>
      <div className="joyride-title" />
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepRemoveTeamMember = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">
          {tc({ key: 'tour:remove_team_member' })}
        </span>
      </div>
      <div className="joyride-container">
        {tc({ key: 'tour:remove_team_member_detail' })}
      </div>
      {ButtonJoyride({
        helpers,
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepSave = ({
  helpers,
  stepNo
}: {
  helpers: StoreHelpers;
  stepNo?: string;
}) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-container">{stepNo}</div>
      <div className="joyride-container">
        <span className="joyride-detail-title">{tc({ key: 'tour:save' })}</span>
      </div>
      <div className="joyride-container">{tc({ key: 'tour:save_detail' })}</div>
      {ButtonJoyride({
        helpers,
        btnType: 'close',
        nextLabel: tc({ key: 'tour:next' }),
        skipLabel: tc({ key: 'tour:skip_tour' })
      })}
    </>
  );
};
export const StepEnd = ({ helpers }: { helpers: StoreHelpers }) => {
  const { tc } = useTextContent();
  return (
    <>
      <div className="joyride-title">
        <p style={{ marginBottom: '1.4rem' }}>
          <strong style={{ fontSize: '24px' }} className="joyride-detail">
            {tc({ key: 'tour:great_work' })}
          </strong>
        </p>
        <span
          style={{ fontSize: '1.1rem', fontWeight: 500 }}
          className="joyride-detail-title"
        >
          {tc({ key: 'tour:team_great_work_1' })}
        </span>
      </div>
      <hr className="joyride-solid" />
      <div className="joyride-detail">
        {tc({ key: 'tour:team_great_work_2' })}
        <BsQuestionCircle size={16} color={'#fff'} />
        {tc({ key: 'tour:team_great_work_3' })}
      </div>
      <br />
      <button
        className="btn joyride-btn-start"
        onClick={() => {
          helpers?.next();
        }}
      >
        <span className="joyride-button-text">
          {tc({ key: 'tour:get_started' })}
        </span>
      </button>
    </>
  );
};
